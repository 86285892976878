export const calculateRows = (text) => {
  if (text) {
    const approximateCharsPerRow = 90; // adjust this value based on your specific textarea width and font size
    const rows = Math.ceil(text.length / approximateCharsPerRow);
    return rows;
  } else {
    return 4;
  }
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  return new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
  }).format(date);
};

// Helper function to format time
export const formatTime = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);
};

export const andriodPlatform = () => {
  return ["MacIntel", "iPhone", "iPad"].includes(
    navigator?.userAgentData?.platform || navigator?.platform
  );
};

// Function to join words with an underscore
export const joinWords = (words) => {
  return words.replace(/\s+/g, "_");
};

// Function to replace underscore with a space
export const splitWords = (words) => {
  return words.replace(/_/g, " ");
};

export const combineTranscripts = (transcripts) => {
  if (typeof transcripts === "string") {
    return transcripts;
  }

  let combined = "";

  transcripts.forEach((transcript) => {
    combined += `${transcript.name}:\n${transcript.transcript}\n\n`;
  });

  return combined;
};

export const sliceTranscripts = (transcripts) => {
  if (typeof transcripts === "string") {
    return transcripts.slice(0, 100);
  }

  let combined = "";

  transcripts.forEach((transcript) => {
    combined += `${transcript.name}:\n${transcript.transcript}\n\n`;
  });

  return combined.slice(0, 100);
};
