import {
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import {
  XMarkIcon,
  MinusIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { splitWords } from "../../utils/helper-functions";
import { DashboardItems } from "../../utils/constant";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import NewTemplate from "../../component/CreateSection";
import { ToastContainer } from "react-toastify";
import { AttioDemoContext } from "../../context/attioDemo";

const exemptedKeys = [
  "recording_id",
  "id",
  "audioKey",
  "createdAt",
  "transcript",
  "aiEnhancedTranscript",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const TemplatesPage = () => {
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openNewSectionModal, setNewSectionModal] = useState(false);
  const [userTemplates, setUserTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const auth = getAuth();

  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  const getTemplates = async (userId) => {
    const userDocRef = doc(db, "user-templates", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      setUserTemplates(userDoc.data()?.templates);
    } else {
      console.log("No such document!");
      return null;
    }
  };

  const deleteTemplate = async (templateId) => {
    setIsLoading(true);
    const userDocRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const templates = userDoc.data()?.templates;
      const updatedTemplates = templates.filter(
        (template) => template.id !== templateId
      );

      await updateDoc(userDocRef, { templates: updatedTemplates });
      await getTemplates(auth?.currentUser?.uid);
      setIsLoading(false);
      console.log("Template deleted successfully");
    } else {
      setIsLoading(false);
      console.log("No such document!");
      return null;
    }
  };

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      getTemplates(auth?.currentUser?.uid);
      handleUserSubscriptionCheck(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  console.log("USER TEMPLATES", userTemplates);

  return (
    <>
      <ToastContainer autoClose />
      <NewTemplate
        openNewSectionModal={openNewSectionModal}
        setNewSectionModal={setNewSectionModal}
        getUserTemplates={getTemplates}
      />
      <div className="bg-[#1f2838ff]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      {attioDemo ? (
                        <div className="flex h-16 shrink-0 items-center py-12">
                          <img
                            className="h-8 w-auto "
                            src={"/images/the_general_partnership_cover.jpeg"}
                            // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                            alt="Your Company"
                          />
                        </div>
                      ) : (
                        <div className="flex h-16 shrink-0 items-center py-12">
                          <img
                            className="h-12 w-auto"
                            src="/images/MedAssist-white.svg"
                            // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                            alt="Your Company"
                          />
                        </div>
                      )}
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive, attioDemo)?.map(
                              (item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    navigate(item.key);
                                  }}
                                >
                                  <a
                                    className={classNames(
                                      item.key === location.pathname
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            {attioDemo ? (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-8 w-auto "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            ) : (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto"
                  src="/images/MedAssist-white.svg"
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            )}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive, attioDemo)?.map(
                      (item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            navigate(item.key);
                          }}
                        >
                          <a
                            className={classNames(
                              item.key === location.pathname
                                ? "bg-indigo-600 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            {attioDemo ? (
              <div className="flex  shrink-0 items-center ">
                {/* <img
                  className="h-8 w-auto "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                /> */}
              </div>
            ) : (
              <img
                src="/images/MedAssist-white.svg"
                alt=""
                className="flex-shrink-0 lg:hidden h-12"
              />
            )}

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              <UserIcon
                onClick={() => navigate("/settings")}
                color="white"
                className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden "
              />
            </div>
          </div>

          <main className="lg:h-[100vh] min-h-[100vh] flex-1 bg-[#1A1A26] overflow-y-scroll">
            <div className="hidden lg:block  lg:no-scrollbar lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 py-8  ">
              <h1 className="text-left text-[30px] font-bold text-white">
                Note Templates
              </h1>
            </div>
            <div className=" px-4 sm:px-6 lg:px-8 py-10  ">
              <div className="felx flex-col space-y-3">
                <div className="flex flex-col space-y-4">
                  {userTemplates?.length > 0 &&
                    userTemplates?.map((template) => {
                      return (
                        <Disclosure as="div" className="" key={template.id}>
                          {({ open }) => (
                            <>
                              <dt>
                                <Disclosure.Button className="flex  w-full items-start justify-between text-left text-gray-900">
                                  <span className="text-[16px] text-white font-semibold leading-7">
                                    {template.name}
                                  </span>
                                  <span className="ml-6 flex h-7 items-center">
                                    {open ? (
                                      <MinusIcon
                                        color="white"
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusIcon
                                        color="white"
                                        className="h-6 w-6 "
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </dt>
                              <Disclosure.Panel as="dd" className="mt-2  ">
                                <div className="flex flex-col px-8  w-full">
                                  <div className="flex mt-4 flex-row justify-end">
                                    <button
                                      isLoading={isLoading}
                                      type="button"
                                      onClick={() =>
                                        deleteTemplate(template?.id)
                                      }
                                      className="inline-flex w-full justify-center rounded-md bg-red-500 px-4 py-2 text-sm font-semibold text-white   shadow-sm  sm:w-auto"
                                    >
                                      {isLoading
                                        ? "Loading..."
                                        : "Delete Template"}
                                    </button>
                                  </div>

                                  {/* show template id */}
                                  <span className="text-[16px] text-white leading-7">
                                    Template ID: {template.id}
                                  </span>

                                  {template?.prompt && (
                                    <>
                                      <div>
                                        <div className="h-[0.5px] my-8 w-full bg-white" />
                                        <div className="flex flex-row justify-start">
                                          <p className="text-[white] text-[18px] ">
                                            System Prompt:
                                          </p>
                                        </div>

                                        <div className="flex mt-3 flex-col space-y-4">
                                          <div>
                                            <textarea
                                              value={template?.prompt}
                                              readOnly={true}
                                              rows={6}
                                              placeholder="Give a description or an example of this section (e.g. For prescriptions, list it in bullets like so: Pimobendan, Penicillin, Wormwood)"
                                              className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm border-2 rounded-md focus:outline-none text-[#fafafaff]`}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div>
                                    <div className="h-[0.5px] my-8 w-full bg-white" />
                                    <div className="flex flex-row justify-start">
                                      <p className="text-[white] text-[18px] ">
                                        Sections:
                                      </p>
                                    </div>

                                    <div className="flex mt-3 flex-col space-y-4">
                                      {template?.sections?.map(
                                        (section, sectionId) => {
                                          return (
                                            <div key={sectionId}>
                                              {/* <input
                                                id="name"
                                                name="Template Name"
                                                type="text"
                                                value={section.section}
                                                readOnly={true}
                                                // onChange={(e) => setTemplateName(e.target.value)}
                                                placeholder="Give your template a name"
                                                required
                                                className="block pl-2 pr-3 w-[30%] bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              /> */}

                                              <span style={{ color: "white" }}>
                                                {splitWords(section.section)}
                                              </span>
                                              <textarea
                                                value={section.description}
                                                readOnly={true}
                                                // onChange={(e) =>
                                                //   setSectionDescription(e.target.value)
                                                // }
                                                rows={6}
                                                placeholder="Give a description or an example of this section (e.g. For prescriptions, list it in bullets like so: Pimobendan, Penicillin, Wormwood)"
                                                className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm border-2 rounded-md focus:outline-none text-[#fafafaff]`}
                                              ></textarea>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="h-[0.5px] my-8 w-full bg-white" />

                                  <div>
                                    <div className="flex flex-row justify-start">
                                      <p className="text-[white] text-[18px] ">
                                        Examples:
                                      </p>
                                    </div>

                                    <div className="flex mt-3 flex-col space-y-4">
                                      {template?.examples?.map(
                                        (example, exampleId) => {
                                          const exampleNotes = Object.keys(
                                            example
                                          )
                                            .filter(
                                              (key) =>
                                                !exemptedKeys.includes(key)
                                            )
                                            .map((key) => ({
                                              name: key,
                                              value: example[key],
                                            }));

                                          return (
                                            <div key={exampleId}>
                                              <div className="flex flex-row justify-start">
                                                <p className="text-[white] text-[14px]">{`Example #${
                                                  exampleId + 1
                                                }`}</p>
                                              </div>
                                              <div className="flex flex-col space-y-5">
                                                {exampleNotes?.map(
                                                  (note, noteId) => {
                                                    return (
                                                      <>
                                                        <span
                                                          style={{
                                                            color: "white",
                                                          }}
                                                        >
                                                          {capitalizeFirstLetter(
                                                            splitWords(
                                                              note.name
                                                            )
                                                          )}
                                                        </span>
                                                        <textarea
                                                          defaultValue={example[
                                                            note.name
                                                          ].replace(
                                                            /\\n/g,
                                                            "\n"
                                                          )}
                                                          readOnly={true}
                                                          // onChange={(e) =>
                                                          //   setSectionDescription(e.target.value)
                                                          // }
                                                          rows={6}
                                                          placeholder="Give a description or an example of this section (e.g. For prescriptions, list it in bullets like so: Pimobendan, Penicillin, Wormwood)"
                                                          className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm border-2 rounded-md focus:outline-none text-[#fafafaff]`}
                                                        ></textarea>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    })}
                </div>
                <div className="mt-12 flex flex-row w-full justify-end">
                  <button
                    type="button"
                    onClick={() => setNewSectionModal(true)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-10 py-2 mt-6 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                  >
                    Create New Template
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(TemplatesPage);
