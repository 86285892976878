import React, { createContext, useContext, useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { getAuth, signOut } from "firebase/auth";

export const WavAudioContext = createContext();

export const WavAudioProvider = ({ children }) => {
  const auth = getAuth();
  const [wavAudio, setWavAudio] = useState(() => {
    const storedWavAudio = localStorage.getItem("wavAudio");
    if (storedWavAudio && storedWavAudio !== "undefined") {
      try {
        return JSON.parse(storedWavAudio);
      } catch (e) {
        console.error("Error parsing wavAudio from localStorage:", e);
        // Handle the error, e.g., by returning a default value or null
        return null;
      }
    }
    return null;
  });
  const toggleWavMode = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const newWavAudioValue = !userData.wavAudio;

      await updateDoc(userDocRef, { wavAudio: newWavAudioValue });
      setWavAudio(newWavAudioValue);
      localStorage.setItem("wavAudio", JSON.stringify(newWavAudioValue));
    }
  };

  useEffect(() => {
    const fetchWavAudio = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const wavAudioValue = userData?.wavAudio;

        setWavAudio(wavAudioValue);
        localStorage.setItem("wavAudio", JSON.stringify(wavAudioValue));
      }
    };

    if (auth?.currentUser?.uid) {
      fetchWavAudio(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  return (
    <WavAudioContext.Provider value={{ wavAudio, toggleWavMode }}>
      {children}
    </WavAudioContext.Provider>
  );
};
