import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { TrialPeriodContext } from "../../context/trialPeriod";
import { getAuth } from "firebase/auth";

export const TrialPeriodExpired = (props) => {
  const auth = getAuth();

  const { openTrialPeriodExpired, setOpenTrialPeriodExpired } = props;

  const { checkSubscriptionStatus } = useContext(TrialPeriodContext);

  return (
    <Transition.Root show={openTrialPeriodExpired}>
      <Dialog className="relative z-10" onClose={setOpenTrialPeriodExpired}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#191A26] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center ">
                    <ExclamationTriangleIcon
                      className="h-10 w-10 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      Trial Recordings Expired
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-white">
                        You've used up your free trial recordings... Subscribe
                        to continue using the app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline   focus-visible:outline-indigo-500"
                    onClick={() => {
                      checkSubscriptionStatus(auth?.currentUser?.uid);
                      setOpenTrialPeriodExpired(false);
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
