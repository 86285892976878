import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, signInWithGooglePopup } from "../../firebase-config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import Banner from "../../component/Banner";
import { getStevenTemplates } from "./helper";
export default function SignUpPage() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // Add this line
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = Cookies.get(); // This will get all cookies as an object
  const setCookie = Cookies.set; // This is a function to set a cookie
  const removeCookie = Cookies.remove; // This is a function to remove a cookie

  const queryParams = new URLSearchParams(location.search);
  const disableStripe = queryParams.get("disable_stripe");

  useEffect(() => {
    if (disableStripe === "true") {
      setCookie("disableStripe", "true", { path: "/" });
    }
  }, [disableStripe, setCookie]);

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userid = userCredential.user.uid;
      const userEmail = userCredential.user.email;

      let userData = {
        id: userid,
        email: userEmail,
        wavAudio: true,
        signupPlatform: "web",
        createdAt: new Date(),
      };

      if (cookies["disableStripe"] !== "true") {
        // Call the create-customer endpoint
        const response = await fetch(
          "https://vetassist-mobile-backend.onrender.com/create-customer",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userEmail,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        userData.stripeCustomerId = data.customer;
      } else {
        userData.isBillingDisabled = true;
      }

      // Store the user data in the users document
      await setDoc(doc(db, "users", userid), userData);

      const stevenTemplates = await getStevenTemplates();
      const userRef = doc(db, "user-templates", userid);

      await setDoc(userRef, {
        templates: stevenTemplates,
      });

      const signInUser = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("SIGNEDIN USER", signInUser.user);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error: ", error);
      setError(error.message); // Update the error state
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithGooglePopup();
      console.log("THE RESPONSE", response);

      const userId = response?.user?.uid;
      const userEmail = response?.user?.email;

      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        let userData = {
          id: userId,
          email: userEmail,
          wavAudio: true,
          signupPlatform: "web",
          createdAt: new Date(),
        };
        // Call the create-customer endpoint
        if (cookies["disableStripe"] !== "true") {
          const customerResponse = await fetch(
            "https://vetassist-mobile-backend.onrender.com/create-customer",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: userEmail,
              }),
            }
          );

          if (!customerResponse.ok) {
            throw new Error(`HTTP error! status: ${customerResponse.status}`);
          }
          const customerData = await customerResponse.json();
          const stripeCustomerId = customerData.customer;

          userData.stripeCustomerId = stripeCustomerId;
        } else {
          userData.isBillingDisabled = true;
        }
        // Store the user data in the user document
        await setDoc(userDocRef);

        const stevenTemplates = await getStevenTemplates();
        const userRef = doc(db, "user-templates", userId);

        await setDoc(userRef, {
          templates: stevenTemplates,
        });
      }
      navigate("/dashboard");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage); // Update the error state
      console.log(errorCode, errorMessage);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col">
      {/* <Banner bgColor="#4d45e6" color="white" /> */}
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 ">
        <div className="flex lg:flex-row flex-1 lg:justify-between  flex-col  ">
          <div className="flex flex-1 flex-col justify-center py-4">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-10 w-auto"
                src="https://voice-receptionist-ai.b-cdn.net/MedAssist%20Logo%20for%20Login%20Page%20v2.svg"
                // src="https://voice-receptionist-ai.b-cdn.net/Logo%20MedAssist%20(1).svg"
                alt="Your Company"
              />
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Create a new account
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <form className="space-y-6" onSubmit={onSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm text-left font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        required
                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {loading ? (
                        <div className="w-4 h-4 border-2 border-t-2 border-white rounded-full animate-spin"></div>
                      ) : (
                        "Sign up"
                      )}
                    </button>
                  </div>

                  {error && <p className="text-red-500">{error}</p>}
                </form>

                <div className="mt-6">
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div className="mt-6 flex flex-row justify-center">
                    <a
                      onClick={handleGoogleSignIn}
                      className="flex w-full   cursor-pointer items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                          fill="#34A853"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6">
                        Google
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-1 text-center text-sm text-gray-500">
          Already a member?{" "}
          <a
            onClick={() => navigate("/sign-in")}
            className="font-semibold  cursor-pointer leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Sign in here.
          </a>
        </p>
      </div>
    </div>
  );
}
