import { Fragment, useState, useContext } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AWS from "aws-sdk";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase-config";
import {
  doc,
  addDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { AttioDemoContext } from "../../context/attioDemo";
import { TailSpin } from "react-loader-spinner";
import { showConfettiFireworks } from "../../utils/showConfettiFireworks";
import toast, { Toaster } from "react-hot-toast";

const spacesEndpoint = new AWS.Endpoint("sfo2.digitaloceanspaces.com");
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO007HAKLKL49L67EFVP",
  secretAccessKey: "td9Qp547sxcrnMezrxUFH4hpiHtJekyWpT8DPEOJ9bA",
});

const nameSpace = [
  {
    id: 1,
    name: "US",
    url: "https://proxy-server-rbn8.onrender.com/medassist-us-transcribe",
  },
  {
    id: 2,
    name: "FRA",
    url: "https://proxy-server-rbn8.onrender.com/medassist-france-transcribe",
  },

  {
    id: 3,
    name: "TESTING",
    url: "https://proxy-server-rbn8.onrender.com/medassist-testing-transcribe",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Uploader(props) {
  const {
    openUploader,
    setOpenUploader,
    currentTemplate,
    getRecordingDocuments,
  } = props;
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);

  const [file, setFile] = useState();
  const [noteTitle, setNoteTitle] = useState(
    attioDemo ? "Deal Memo" : "Medical Record"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedRecord, setUploadedRecord] = useState();
  const [blobURL, setBlobURL] = useState("");
  const [contentType, setContentType] = useState("");
  const [selected, setSelected] = useState(nameSpace[0]);
  const [recordingId, setRecordingId] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const auth = getAuth();

  const { getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "audio/*": [".mp3", ".mp4", ".wav", ".m4a"], // Updated to accept mp3 files
    },
    onDrop: async (acceptedFiles) => {
      console.log("THE ACCEPTED FILES", acceptedFiles);

      setUploadedRecord(acceptedFiles);

      const originalBlob = acceptedFiles[0];
      console.log("THE ORIGINAL BLOB", originalBlob);
      const createdBlobUrl = URL.createObjectURL(originalBlob);

      console.log("The BlobUrl", createdBlobUrl);
      setBlobURL(createdBlobUrl);

      // Determine the file extension based on the MIME type
      let fileExtension;
      switch (originalBlob.type) {
        case "audio/mpeg":
          setContentType("audio/mp3");
          fileExtension = ".mp3";

          break;
        case "video/mp4":
          setContentType("video/mp4");
          fileExtension = ".mp4";
          break;
        case "audio/wav":
          setContentType("audio/wav");
          fileExtension = ".wav";
          break;
        case "audio/x-m4a":
          setContentType("audio/x-m4a");
          fileExtension = ".m4a";
          break;
        default:
          console.error("Unsupported audio type:", originalBlob.type);
          return;
      }

      const fileName = `${
        auth?.currentUser?.uid
      }/recording_${Date.now()}${fileExtension}`;

      console.log("NEW-FILE-NAME", fileName);

      // Creating a new File from the original blob data
      const newFile = new File([originalBlob], fileName, {
        type: originalBlob.type,
      });

      console.log("THE NEW FILE", newFile);

      setFile(newFile);
    },
  });

  const [attioJson, setAttioJson] = useState(null);
  const [attioJsonLoading, setAttioJsonLoading] = useState(false);
  const [attioUrl, setAttioUrl] = useState("");
  let intervalId;

  const checkDocument = async (record_id) => {
    console.log("checkDocument>  --->", record_id);

    const q = query(
      collection(db, "attio"),
      where("recording_id", "==", record_id)
    );

    const querySnapshot = await getDocs(q);

    console.log("THE QUERY SNAPSHOT --->", querySnapshot);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        setAttioJson(doc.data().company);
        // clearInterval(intervalId);
        // stop checking once document is found
      });
      // setIsLoading(false);
    } else {
      console.log("No such document!");
    }
  };

  const handleRecordingAudioUpload = () => {
    setShowPercentage(true);
    setIsLoading(true);

    const params = {
      Bucket: "vet-appt-recordings",
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };

    console.log("THE PARAMS ===>", params);

    const upload = s3.upload(params);

    upload.on("httpUploadProgress", function (progress) {
      setShowPercentage(true);
      const percentage = Math.round((progress.loaded / progress.total) * 100);

      console.log(`Upload progress: ${percentage}%`);
      setUploadPercentage(percentage);
    });

    upload.send(async (err, data) => {
      try {
        console.log("S3 upload done. Starting on iniating transcription...");
        const recordingIdUUID = uuidv4();
        const docRef = await addDoc(collection(db, "recordings"), {
          title: noteTitle,
          id: `${recordingIdUUID}`,
          record_key: data.Key,
          userId: auth?.currentUser?.uid,
          createdAt: new Date(),
        });

        const runloopDocRef = await addDoc(collection(db, "recordings"), {
          title: noteTitle,
          id: `${recordingIdUUID}-runloop`,
          record_key: data.Key,
          userId: auth?.currentUser?.uid,
          createdAt: new Date(),
        });

        console.log("Uploaded recording");
        // Update the inbox to show the new recording
        await getRecordingDocuments(auth?.currentUser?.uid);
        const docSnap = await getDoc(doc(db, "recordings", docRef.id));

        const runloopDocSnap = await getDoc(
          doc(db, "recordings", runloopDocRef.id)
        );

        // Make a POST request
        const transcriptionBody = JSON.stringify({
          fileName: docSnap.data().record_key,
          recordId: docSnap.data().id,
          userId: auth?.currentUser?.uid,
          templateId: currentTemplate ? currentTemplate?.id : null,
        });
        console.log("transcriptionBody: ", transcriptionBody);
        setShowPercentage(false);
        console.log("CALLING transcription api endpoint");

        if (attioDemo) {
          try {
            setRecordingId(docSnap.data().id);
            const response = await fetch(
              "https://proxy-server-rbn8.onrender.com/generate_attio",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer sk_prod_qrdbYPEsgrzeeFVebd1tt+/qe8li8Xq+RYJkViHztCE=",
                },

                body: JSON.stringify({
                  request: {
                    recording_id: docSnap.data().id,
                    file_name: docSnap.data().record_key,
                    user_id: auth?.currentUser?.uid,
                  },
                }),
              }
            );
            if (!response.ok) {
              setIsLoading(false);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            intervalId = setInterval(
              () => checkDocument(docSnap.data().id),
              5000
            );
            setUploadPercentage(0);
            // setShowConfetti(true);
            // toast.success("Audio File uploaded!");
            // setNoteTitle("");
            setBlobURL(null);
            setUploadedRecord(null);
          } catch (error) {
            setIsLoading(false);
            toast.error("error occured while making the request");

            console.error("An error occurred while making the request:", error);
          }
        } else {
          fetch(`${selected.url}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: transcriptionBody,
          });

          fetch("https://proxy-server-rbn8.onrender.com/generate_soap", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer sk_prod_qrdbYPEsgrzeeFVebd1tt+/qe8li8Xq+RYJkViHztCE=",
            },
            body: JSON.stringify({
              request: {
                recording_id: runloopDocSnap.data().id,
                user_id: auth?.currentUser?.uid,
                template_id: currentTemplate ? currentTemplate?.id : "",
                uploaded_transcript: "",
                type: "newRecording",
                file_name: runloopDocSnap.data().record_key,
              },
            }),
          });
          setIsLoading(false);
          toast.success("Audio File uploaded!");
          setNoteTitle("");
          setBlobURL(null);
          setUploadedRecord(null);
          setOpenUploader(false);
        }

        console.log("Transcription api endpoint has been called");
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };

  const deleteFile = () => {
    setFile(null);
    setBlobURL(null);
    setUploadedRecord(null);
  };

  // console.log("RECORDING ID  --->", recordingId);
  // console.log(" JSON   --->", attioJson);

  const handleAttioPost = async () => {
    setAttioJsonLoading(true);
    try {
      const response = await fetch(
        "https://proxy-server-rbn8.onrender.com/post_attio",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer sk_prod_qrdbYPEsgrzeeFVebd1tt+/qe8li8Xq+RYJkViHztCE=",
          },
          body: JSON.stringify({
            request: {
              recording_id: recordingId,
              user_id: auth?.currentUser?.uid,
            },
          }),
        }
      );

      if (!response.ok) {
        setAttioJsonLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAttioJsonLoading(false);
      // setAttioJson(null);
      setIsLoading(false);
      // setOpenUploader(false);
      setRecordingId("");
      toast.success("JSON added to CRM");
      showConfettiFireworks(2);

      // If you need to use the response, you can parse it here
      const data = await response.json();
      console.log("THE DATA FROM ATTIO", JSON.parse(data?.response));
      const attioData = JSON.parse(data?.response);
      setAttioUrl(attioData?.data?.attio_url);
    } catch (error) {
      setAttioJsonLoading(false);
      toast.error("error occured while adding JSON to CRM");

      console.error("An error occurred while making the request:", error);
    }
  };

  return (
    <>
      <Toaster />
      <Transition.Root show={openUploader} as={Fragment} static={true}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            console.log("hello");
          }}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#191A26] px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50%] sm:p-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-0 w-full  text-center  sm:mt-0 sm:text-left">
                      <div className=" flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:justify-between sm:items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-[18px] text-left  font-semibold leading-6 text-gray-50"
                        >
                          Upload Audio
                        </Dialog.Title>

                        <Listbox value={selected} onChange={setSelected}>
                          {({ open }) => (
                            <>
                              <div className="relative w-[40%]">
                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-[#2F3146] py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                  <span className="flex items-center space-x-2">
                                    <span className=" block truncate font-normal text-[14px] text-gray-200">
                                      {selected.name}
                                    </span>
                                    <img
                                      src="/images/information-circle.svg"
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    />
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <ChevronDownIcon
                                      className="h-5 w-5 text-white "
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {nameSpace.map((nameSpace) => (
                                      <Listbox.Option
                                        key={nameSpace.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-indigo-600 text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={nameSpace}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {nameSpace.name}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                      <div className="mt-2">
                        <div className="flex flex-row items-center space-x-2">
                          <p className="text-white font-semibold ">Template:</p>
                          {attioDemo ? (
                            <p className="text-gray-400 text-[14px]">
                              Attio Template
                            </p>
                          ) : (
                            <p className="text-gray-400 text-[14px]">{` ${
                              currentTemplate?.name
                                ? currentTemplate?.name
                                : "MedAssist SOAP"
                            }`}</p>
                          )}
                        </div>
                      </div>

                      <div>
                        <input
                          id="email"
                          name="note-title"
                          type="text"
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                          placeholder="Note Title "
                          required
                          className="block mt-4 pl-2 pr-3 w-full  bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {/* //activate upload here */}
                      {attioUrl ? (
                        <div className="mt-2 p-6 flex flex-col w-full justify-center items-center min-h-[250px]  rounded-md">
                          {/* <p className="text-white">{attioUrl}</p> */}

                          <p className="text-white mb-2">
                            {"GP AI Agent has updated Attio CRM for you 🎉"}
                          </p>
                          <a
                            href="https://app.attio.com/zgp/company/8cea141d-9355-43b4-b0cb-bd4736e8cd36/activity"
                            className="text-[#0000FF] underline"
                          >
                            {
                              "https://app.attio.com/zgp/company/8cea141d-9355-43b4-b0cb-bd4736e8cd36/activity"
                            }
                          </a>
                        </div>
                      ) : (
                        <>
                          {attioJson ? (
                            <div className="mt-2 p-6  w-full justify-center items-center min-h-[250px]  overflow-x-scroll rounded-md">
                              <pre className="text-white">
                                {attioJson
                                  ? JSON.stringify(attioJson, null, 2)
                                  : ""}
                              </pre>
                            </div>
                          ) : (
                            <>
                              {isLoading ? (
                                <div className="mt-2 p-6 flex flex-row w-full justify-center items-center min-h-[250px]  rounded-md">
                                  <div>
                                    <div className="flex flex-row w-full justify-center">
                                      <TailSpin
                                        // visible={true}
                                        height="100"
                                        width="100"
                                        color="#4d45e6"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                      />
                                    </div>
                                    {!showPercentage && (
                                      <p className="text-white text-center mt-4">
                                        GP AI Agent is generating the POST
                                        request to Attio CRM
                                      </p>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  {...getRootProps()}
                                  className="mt-2 p-6 flex flex-row w-full justify-center items-center min-h-[250px] border rounded-md bg-[#242536] border-[#393A46]"
                                >
                                  <div
                                    onClick={open}
                                    className=" cursor-pointer"
                                  >
                                    <div className="flex flex-row justify-center">
                                      <img src="/images/recording-file.svg" />
                                    </div>
                                    <p className="mt-6 text-[12px] text-center font-normal text-gray-400">
                                      Drop or Browse your recording here to
                                      upload
                                    </p>
                                    <p className="text-[10px] text-center font-normal text-gray-400">
                                      {`Source file (MP3, MP4, M4A, WAV)`}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {!attioJson || !attioUrl ? (
                        <div className="mt-4">
                          {isLoading ? (
                            <>
                              {showPercentage ? (
                                <>
                                  <text class="text-gray-400 text-[14px]">
                                    AI Agent is transcribing the meeting.{" "}
                                    {uploadPercentage}% done...
                                  </text>
                                  <div class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700 mt-6">
                                    <div
                                      class="h-4 bg-indigo-600 rounded-full dark:bg-indigo-500 text-white"
                                      style={{ width: `${uploadPercentage}%` }}
                                    ></div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="text-[12px] text-left font-normal text-gray-400">
                                Your recording file
                              </p>
                              <div className=" mt-2 flex flex-row items-center space-x-6">
                                {uploadedRecord ? (
                                  <audio
                                    src={blobURL}
                                    controls="controls"
                                    style={{ width: "100%", flex: 1 }}
                                  />
                                ) : (
                                  <div className="flex-1 bg-[#242536] min-h-[50px] rounded-md border border-[#393A46] py-3 px-3">
                                    <p className="text-[12px] text-left font-normal text-gray-400">
                                      no-file
                                    </p>
                                  </div>
                                )}

                                {uploadedRecord && (
                                  <img
                                    onClick={deleteFile}
                                    className="cursor-pointer"
                                    src="/images/Delete.svg"
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {attioJson ? (
                      <>
                        {" "}
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                          onClick={() => handleAttioPost()}
                        >
                          {attioJsonLoading ? "Loading..." : "Add Json to CRM"}
                        </button>
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <button
                            type="button"
                            disabled={true}
                            className=" inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                            onClick={() => handleRecordingAudioUpload()}
                          >
                            Upload
                          </button>
                        )}
                      </>
                    )}

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-[#262838ff] px-3 py-2 text-sm font-semibold text-indigo-300 shadow-sm ring-1 ring-inset ring-indigo-300  sm:mt-0 sm:w-auto"
                      onClick={() => setOpenUploader(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
