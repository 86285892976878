import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition, Disclosure, Switch } from "@headlessui/react";
import { XMarkIcon, UserIcon } from "@heroicons/react/24/outline";
import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { DashboardItems } from "../../utils/constant";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { AttioDemoContext } from "../../context/attioDemo";

import { WavAudioContext } from "../../context/wavAudioContext";
import { ToastContainer } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SettingPage = () => {
  const { wavAudio, toggleWavMode } = useContext(WavAudioContext);

  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [debugMode, setDebugMode] = useState(false);

  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const auth = getAuth();

  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  const toggleUserDebugMode = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();

      const debugMode = userData.debugMode;
      if (debugMode) {
        // If debugMode is true, set it to false
        await updateDoc(userDocRef, { debugMode: false });
        setDebugMode(false);
      } else {
        // If debugMode is not true (or undefined), set it to true
        await updateDoc(userDocRef, { debugMode: true });
        setDebugMode(true);
      }
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/sign-in");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const fetchDebugMode = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      setDebugMode(userData.debugMode || false);
    }
  };

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      handleUserSubscriptionCheck(auth?.currentUser?.uid);
      fetchDebugMode(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  return (
    <>
      <ToastContainer autoClose />

      <div className="bg-[#1f2838ff]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    {attioDemo ? (
                      <div className="flex h-16 shrink-0 items-center py-12">
                        <img
                          className="h-8 w-auto "
                          src={"/images/the_general_partnership_cover.jpeg"}
                          // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                          alt="Your Company"
                        />
                      </div>
                    ) : (
                      <div className="flex h-16 shrink-0 items-center py-12">
                        <img
                          className="h-12 w-auto"
                          src="/images/MedAssist-white.svg"
                          // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                          alt="Your Company"
                        />
                      </div>
                    )}
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive, attioDemo)?.map(
                              (item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    navigate(item.key);
                                  }}
                                >
                                  <a
                                    className={classNames(
                                      item.key === location.pathname
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            {attioDemo ? (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-8 w-auto "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            ) : (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto"
                  src="/images/MedAssist-white.svg"
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            )}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive, attioDemo)?.map(
                      (item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            navigate(item.key);
                          }}
                        >
                          <a
                            className={classNames(
                              item.key === location.pathname
                                ? "bg-indigo-600 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              {/* <UserIcon
                color="white"
                className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden "
              /> */}
              {/* <img
                src="/images/pet.svg"
                alt=""
                
              /> */}
            </div>
          </div>

          <main className="lg:h-[100vh] min-h-[100vh] flex-1 bg-[#1A1A26] overflow-y-scroll">
            <div className="hidden lg:block  lg:no-scrollbar lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 py-8  ">
              <h1 className="text-left text-[30px] font-bold text-white">
                Settings
              </h1>
            </div>
            <div className=" px-4  sm:px-6 lg:px-8 py-10  ">
              <div className="flex space-y-4 flex-col">
                <p className="text-lg font-bold  text-white text-left">
                  Security
                </p>
                <div className=" flex flex-row w-full justify-start">
                  <button
                    type="button"
                    onClick={() => handleSignOut()}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-10 py-2 text-sm font-semibold text-white   shadow-sm sm:w-auto"
                  >
                    Log out
                  </button>
                </div>
              </div>

              <div className="flex mt-8 space-y-4 flex-col">
                <p className="text-lg font-bold  text-white text-left">
                  Debug Mode
                </p>
                <div className=" flex flex-row w-full justify-start">
                  <Switch
                    checked={debugMode}
                    onChange={() =>
                      toggleUserDebugMode(auth?.currentUser?.uid, "debugMode")
                    }
                    className={classNames(
                      debugMode ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        debugMode ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="flex mt-8 space-y-4 flex-col">
                <p className="text-lg font-bold  text-white text-left">
                  Record in high-fidelity audio
                </p>
                <p className="text-white text-left text-sm">
                  (This may increase your transcription time)
                </p>

                <div className=" flex flex-row w-full justify-start">
                  <Switch
                    checked={wavAudio}
                    onChange={() => toggleWavMode(auth?.currentUser?.uid)}
                    className={classNames(
                      wavAudio ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        wavAudio ? "translate-x-5" : "translate-x-0",

                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="flex mt-8 space-y-4 flex-col">
                <p className="text-lg font-bold  text-white text-left">
                  Attio Mode
                </p>

                <div className=" flex flex-row w-full justify-start">
                  <Switch
                    checked={attioDemo}
                    onChange={() => toggleDebugMode(auth?.currentUser?.uid)}
                    className={classNames(
                      attioDemo ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        attioDemo ? "translate-x-5" : "translate-x-0",

                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(SettingPage);
