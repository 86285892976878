import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition, Disclosure, Switch } from "@headlessui/react";
import { XMarkIcon, UserIcon } from "@heroicons/react/24/outline";

import { CheckIcon } from "@heroicons/react/20/solid";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuthorization } from "../../hoc/auth.hoc";
import { DashboardItems } from "../../utils/constant";
import { AttioDemoContext } from "../../context/attioDemo";
import { TrialPeriodContext } from "../../context/trialPeriod";
const pricing = {
  frequencies: [{ value: "monthly", label: "Monthly", priceSuffix: "/ month" }],
  tiers: [
    {
      name: "MedAssist Plan",
      id: "tier-enterprise",
      href: "#",
      price: { monthly: "$99.00" },
      description: "Easy and Fast Medical Note Creation.",
      features: [
        "Unlimited Notes",
        "Unlimited Recordings",
        "AI-powered transcription",
        "AI-powered EHR inputs/note generation",
        "AI-powered editing of notes",
        "No customizeable notes",
        "No customer support time",
      ],
      mostPopular: true,
      // lookup_key: "price_1P9WfWLGVk1TtExGlZmT22Ep",
      lookup_key: "price_1PLYX4LGVk1TtExGNxihDEpu",
    },
    {
      name: "MedAssist Pro Plan",
      id: "tier-enterprise",
      href: "#",
      price: { monthly: "$179.00" },
      description: "Easy and Fast Medical Note Creation.",
      features: [
        "Everything in the regular plan",
        "Dedicated customer support time",
        "Customizable note templates fit for your practice & EHR",
      ],
      mostPopular: true,
      lookup_key: "price_1P45OQLGVk1TtExG5QCkiALN",
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubscriptionPage() {
  const auth = getAuth();
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);
  const { userStatus } = useContext(TrialPeriodContext);
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [subscriptionIdAvailable, setSubscriptionIdAvailable] = useState(false);

  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
    if (userDocSnap.exists() && userDocSnap.data().subscriptionId) {
      setSubscriptionIdAvailable(true);
    }
  };

  const handleSubscription = async (lookup_key) => {
    setIsLoading(true);
    const userId = auth.currentUser.uid;

    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      const customerId = userDocSnap.data().stripeCustomerId;
      const isSubscriptionCancelled =
        userDocSnap.data().isSubscriptionCancelled;
      const domain = window.location.origin;
      console.log("YOOOOOO", {
        customerId: customerId,
        priceId: lookup_key,
        domain: domain,
        isSubscriptionCancelled: isSubscriptionCancelled ? true : false,
      });

      const response = await fetch(
        "https://vetassist-mobile-backend.onrender.com/create-subscription-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: customerId,
            priceId: lookup_key,
            domain: domain,
            isSubscriptionCancelled: isSubscriptionCancelled ? true : false,
          }),
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsLoading(false);

      // Redirect to the session URL
      window.location.href = data.url;
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred:", error);
    }
  };

  async function createBillingSession() {
    try {
      const userId = auth.currentUser?.uid;
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      // Get the customerId from the user document
      const customerId = userDocSnap.data().stripeCustomerId;
      const domain = window.location.origin;

      console.log("THE CUSTOMER ID IS", customerId);
      const response = await fetch(
        "https://vetassist-mobile-backend.onrender.com/create-billing-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customerId, domain }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // This will be the URL of the Stripe Billing Portal session
      const url = data.url;

      // You can redirect the user to this URL
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      handleUserSubscriptionCheck(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  console.log("WHAT IS THE USER STATUS", userStatus);
  return (
    <div className="bg-[#1f2838ff] ">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                  {attioDemo ? (
                    <div className="flex h-16 shrink-0 items-center py-12">
                      <img
                        className="h-8 w-auto "
                        src={"/images/the_general_partnership_cover.jpeg"}
                        // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                  ) : (
                    <div className="flex h-16 shrink-0 items-center py-12">
                      <img
                        className="h-12 w-auto"
                        src="/images/MedAssist-white.svg"
                        // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                  )}
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {DashboardItems(subscriptionActive, attioDemo)?.map(
                            (item) => (
                              <li
                                key={item.name}
                                onClick={() => {
                                  navigate(item.key);
                                }}
                              >
                                <a
                                  className={classNames(
                                    item.key === location.pathname
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
          {attioDemo ? (
            <div className="flex h-16 shrink-0 items-center py-12">
              <img
                className="h-8 w-auto "
                src={"/images/the_general_partnership_cover.jpeg"}
                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
              />
            </div>
          ) : (
            <div className="flex h-16 shrink-0 items-center py-12">
              <img
                className="h-12 w-auto"
                src="/images/MedAssist-white.svg"
                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
              />
            </div>
          )}
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {DashboardItems(subscriptionActive, attioDemo)?.map(
                    (item) => (
                      <li
                        key={item.name}
                        onClick={() => {
                          navigate(item.key);
                        }}
                      >
                        <a
                          className={classNames(
                            item.key === location.pathname
                              ? "bg-indigo-600 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-60 bg-[#1A1A26]">
        <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <img
              src="/images/hamburger-menu.svg"
              className="h-6 w-6"
              aria-hidden="true"
            />
          </button>

          <div className="inline-flex items-center lg:hidden gap-x-1.5">
            {/* <UserIcon
            color="white"
            className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden "
          /> */}
            {/* <img
            src="/images/pet.svg"
            alt=""
            
          /> */}
          </div>
        </div>

        <main className="lg:h-[100vh] min-h-[100vh] flex-1 bg-[#1A1A26]  pb-6 overflow-y-scroll">
          <div className="hidden lg:block  lg:no-scrollbar lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 py-8  ">
            <h1 className="text-left text-[30px] font-bold text-white">
              Subscription
            </h1>
          </div>

          <div className="mx-auto  max-w-7xl px-6  lg:px-8">
            {/* <div className="mx-auto  text-left">
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
                Get a 14-day trial to MedAssist
              </h1>
            </div> */}
            {/* TODO: bold the $6/day */}
            {(userStatus.isOnTrialPeriod || !userStatus.isOnTrialPeriod) &&
              !userStatus.hasSubscriptionId &&
              !userStatus.isBillingDisabled && (
                <p className="mx-auto mt-6 text-left text-lg leading-8 text-white">
                  Get a <strong>14-day free trial</strong> to try out MedAssist,
                  then just $3-6/day. Cancel anytime. We guarantee that you'll get
                  more value out of MedAssist than your daily Starbucks fix! ☕
                </p>
              )}

            {userStatus?.hasSubscriptionId ? (
              <div className="flex flex-row items-center space-x-10">
                <p className="text-white">
                  You are currently on the{" "}
                  <span className="font-semibold">Premium</span> plan.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    if (userStatus.hasSubscriptionId) {
                      console.log("YEHHHH");
                      createBillingSession();
                    }
                  }}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Manage Subscription
                </button>
              </div>
            ) : (
              <></>
            )}
            {!userStatus.isBillingDisabled && (
              <div className="flex flex-row md:gap-10 mt-10  justify-center">
                {pricing.tiers.map((tier, tierId) => (
                  <div
                    key={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "ring-2 ring-indigo-600"
                        : "ring-1 ring-gray-200 ",
                      "rounded-3xl p-4 cursor-pointer min-w-[380px] max-w-[380px] "
                    )}
                  >
                    <h2
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-white" : "text-gray-900",
                        "text-lg font-semibold leading-8"
                      )}
                    >
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm leading-6 text-white">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex flex-row justify-center items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-indigo-500">
                        {tier.price[frequency.value]}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-white">
                        {frequency.priceSuffix}
                      </span>
                    </p>
                    <span className="text-sm text-white font-semibold">
                      after 14-day free trial
                    </span>
                    <a
                      aria-describedby={tier.id}
                      onClick={() => {
                        handleSubscription(tier.lookup_key);
                      }}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                        "mt-6 flex flex-row justify-center  rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      {isLoading ? (
                        <div className="w-4 h-4 border-2 border-t-2 border-white rounded-full animate-spin"></div>
                      ) : (
                        "Start 14-day trial"
                      )}
                    </a>
                    <ul
                      role="list"
                      className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li
                          key={feature}
                          className="flex  gap-x-3 text-white text-left"
                        >
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
