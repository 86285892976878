import { useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  startAfter,
  limit,
} from "@firebase/firestore";

const PAGE_SIZE = 10; // Number of transcripts per page

const useTranscriptsByUserId = () => {
  const [userTranscripts, setUserTranscripts] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loadingNewTranscripts, setLoadingNewTranscripts] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getTranscriptsByUserId = async (userId) => {
    console.log("CALLLLLED >>");
    setLoadingNewTranscripts(true);
    try {
      // Query the "recordings" collection for the user's recordings
      const recordingsRef = collection(db, "recordings");
      let recordingsQuery = query(
        recordingsRef,
        where("userId", "==", userId),
        orderBy("createdAt", "desc"), // Assuming you want the newest first
        limit(PAGE_SIZE)
      );

      // If not the first page, start after the last seen document
      if (lastDoc) {
        recordingsQuery = query(recordingsQuery, startAfter(lastDoc));
      }

      const recordingsSnapshot = await getDocs(recordingsQuery);

      // If no recordings found, return an empty array
      if (recordingsSnapshot.empty) {
        setHasMore(false);
        setLoadingNewTranscripts(false);
        return;
      }

      // Set the last document for the next page query
      setLastDoc(recordingsSnapshot.docs[recordingsSnapshot.docs.length - 1]);

      // Initialize an array to hold all transcripts
      let newTranscripts = [];

      // For each recording, query the "transcripts" collection for the corresponding transcript
      for (const recordingDoc of recordingsSnapshot.docs) {
        const recordingData = recordingDoc.data();

        const transcriptsRef = collection(db, "transcript");
        const transcriptsQuery = query(
          transcriptsRef,
          where("recording_id", "==", recordingData.id)
        );
        const transcriptsSnapshot = await getDocs(transcriptsQuery);

        // If no transcripts found for the recording, continue to the next recording
        if (transcriptsSnapshot.empty) {
          continue;
        }

        // Add each transcript to the newTranscripts array
        transcriptsSnapshot.forEach((transcriptDoc) => {
          const transcriptData = transcriptDoc.data();

          newTranscripts.push({
            transcriptId: transcriptData.id,
            noteName: recordingData?.title || "Medical Note",
            transcript: transcriptData.transcript,
            recordingId: transcriptData.recording_id,
            createdAt: transcriptData.createdAt, // Convert Firestore Timestamp to JavaScript Date
          });
        });
      }

      // Update the state with all transcripts after fetching
      setUserTranscripts((prevTranscripts) => [
        ...prevTranscripts,
        ...newTranscripts,
      ]);
      setHasMore(recordingsSnapshot.docs.length === PAGE_SIZE);
    } catch (error) {
      console.error("Error fetching transcripts: ", error);
    } finally {
      setLoadingNewTranscripts(false);
    }
  };

  return {
    userTranscripts,
    getTranscriptsByUserId,
    loadingNewTranscripts,
    hasMore,
  };
};

export default useTranscriptsByUserId;
