import React, { createContext, useContext, useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
export const TrialPeriodContext = createContext();

export const TrialPeriodProvider = ({ children }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [trialPeriodRecording, setTrialPeriodRecording] = useState(() => {
    const storedTrialCount = localStorage.getItem("trialCount");
    if (storedTrialCount && storedTrialCount !== "undefined") {
      try {
        return JSON.parse(storedTrialCount);
      } catch (e) {
        console.error("Error parsing storedTrialCount from localStorage:", e);
        // Handle the error, e.g., by returning a default value or null
        return null;
      }
    }
    return null;
  });

  const [userStatus, setUserStatus] = useState(() => {
    const storedUserStatus = localStorage.getItem("userStatus");
    if (storedUserStatus && storedUserStatus !== "undefined") {
      console.log("YOU ARE BEING CALLED");
      try {
        return JSON.parse(storedUserStatus);
      } catch (e) {
        console.error("Error parsing storedTrialCount from localStorage:", e);
        // Handle the error, e.g., by returning a default value or null
        return false;
      }
    }
    return false;
  });

  const checkUserStatus = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    console.log("USER DOC SNAP");

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();

      const isBillingDisabled = !!userData.isBillingDisabled;
      console.log("USER BILLING DISABLLLED", userData.isBillingDisabled);
      const hasSubscriptionId = !!userData.subscriptionId;
      console.log("USER Subscription", userData.subscriptionId);
      const isSubscriptionCancelled = !!userData.isSubscriptionCancelled;
      console.log("USER Subscription CANCELLED", isSubscriptionCancelled);
      const trialPeriod = userData.trialPeriod || 0;

      const userStatusData = {
        isBillingDisabled: isBillingDisabled,
        hasActiveSubscription: hasSubscriptionId && !isSubscriptionCancelled,
        hasCancelledSubscription: hasSubscriptionId && isSubscriptionCancelled,
        hasSubscriptionId: hasSubscriptionId,
        isOnTrialPeriod:
          !isBillingDisabled && !hasSubscriptionId && trialPeriod < 3,
      };

      console.log("USER STATUS DATA", userStatusData);

      setUserStatus(userStatusData);

      localStorage.setItem("userStatus", JSON.stringify(userStatusData));
    } else {
      console.error("User does not exist.");
      // Reset the user status if user doesn't exist
      setUserStatus({
        isBillingDisabled: false,
        hasActiveSubscription: false,
        hasCancelledSubscription: false,
        hasSubscriptionId: false,
        isOnTrialPeriod: false,
      });
    }
  };

  const updateTrialPeriodRecording = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      // Check if trialPeriod exists and is a number, otherwise start at 1
      const currentTrialPeriod =
        typeof userData.trialPeriod === "number" ? userData.trialPeriod : 0;
      const newTrialPeriodValue = currentTrialPeriod + 1;

      await updateDoc(userDocRef, { trialPeriod: newTrialPeriodValue });
      setTrialPeriodRecording(newTrialPeriodValue);
      localStorage.setItem("trialCount", JSON.stringify(newTrialPeriodValue));
      await checkUserStatus(userId);
    } else {
      // If the document does not exist, you might want to handle that case as well
    }
  };

  const fetchAndSetTrialPeriod = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const trialPeriodValue = userData.trialPeriod || 0;

      // Set the trialPeriod in the state
      setTrialPeriodRecording(trialPeriodValue);

      // Set the trialPeriod in the localStorage
      localStorage.setItem("trialCount", JSON.stringify(trialPeriodValue));

      return trialPeriodValue;
    } else {
      // Handle the case where the user document does not exist
      console.error("User document does not exist.");
      return null;
    }
  };

  const checkSubscriptionStatus = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      if (userData.isSubscriptionCancelled) {
        navigate("/subscription");
      } else if (userData.trialPeriod) {
        navigate("/subscription");
      } else {
        console.log("User has billing disabled");
        return;
      }
    } else {
      console.error("User does not exist.");
    }
  };

  useEffect(() => {
    console.log("HELLO");
    auth.onAuthStateChanged((user) => {
      console.log("USER", user);
      if (user) {
        fetchAndSetTrialPeriod(auth?.currentUser?.uid);
        checkUserStatus(user.uid);
      }
    });
  }, []);

  return (
    <TrialPeriodContext.Provider
      value={{
        userStatus,
        trialPeriodRecording,
        updateTrialPeriodRecording,
        checkSubscriptionStatus,
      }}
    >
      {children}
    </TrialPeriodContext.Provider>
  );
};

//Todo:
// 1. I want to be able to check if a user has used up their trial period, also if they have cancelled subscription
// 2.Create a new component pop up that shows up when certain actions wanna be performed when a user has used up their trial period
// 2. Create a new component that shows the trial period and the number of days left
