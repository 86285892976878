import { Fragment, useState, useCallback, useEffect } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import AWS from "aws-sdk";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase-config";
import { useDropzone } from "react-dropzone";
import {
  getDocs,
  query,
  where,
  doc,
  setDoc,
  addDoc,
  getDoc,
  collection,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { XMarkIcon, PencilIcon, ClockIcon } from "@heroicons/react/24/outline";
import useTranscriptsByUserId from "../../hooks/useTranscriptsByUserId";

import { TextArea } from "../TextArea/TextArea";
import { SectionBox } from "../SectionBox";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  joinWords,
  splitWords,
  formatDate,
  formatTime,
  combineTranscripts,
  sliceTranscripts,
} from "../../utils/helper-functions";

const spacesEndpoint = new AWS.Endpoint("sfo2.digitaloceanspaces.com");
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO007HAKLKL49L67EFVP",
  secretAccessKey: "td9Qp547sxcrnMezrxUFH4hpiHtJekyWpT8DPEOJ9bA",
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewTemplate(props) {
  const auth = getAuth();
  const { openNewSectionModal, setNewSectionModal, getUserTemplates } = props;
  const [templateId, setTemplateId] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [originalSectionName, setOriginalSectionName] = useState("");
  // initiate a section edit
  const [initiateSectionEdit, setInitiateSectionEdit] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const [sectionDescription, setSectionDescription] = useState("");
  const [userAddedSection, setUserAddedSection] = useState();
  const [userAddedExamples, setUserAddedExamples] = useState();
  const [transcriptNote, setTranscriptNote] = useState();
  const [transcript, setTranscript] = useState("");
  const [selectedTranscript, setSelectedTranscript] = useState();
  const [selectedTranscriptRecordId, setSelectedTranscriptRecordId] =
    useState();
  const [userExampleSoap, setUserExampleSoap] = useState([]);
  const [recordingId, setRecordingId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();
  const [showUploadAudio, setShowUploadAudio] = useState(true);
  const [showUploadeBtn, setShowUploadBtn] = useState(false);
  const [uploadedRecord, setUploadedRecord] = useState();
  const [blobURL, setBlobURL] = useState("");
  const [contentType, setContentType] = useState("");
  const [gptSystemMessage, setGptSystemMessage] = useState(
    "I am a world renowned veterinarian trained to generate SOAP notes from medical transcripts. I correct mistakes in notes and fix medical terminology names, mispronunciations, and more. The user will give me a transcript of the conversation. I will need to convert it to a note with SOAP and other relevant EHR sections mentioned below. I am concise and use bullets. I write very well and am a professional veterinarian with 20 years of experience."
  );
  const [userTemplates, setUserTemplates] = useState(false);

  const [selected, setSelected] = useState(
    "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-c43fccea-9548-427c-aa2d-0bc6e9a3ed9f/notes/transcribe"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [cloneSectionLoading, setCloneSectionLoading] = useState(false);
  const [uploadedTranscript, setUploadedTranscript] = useState("");
  const [useExisitingTranscript, setUseExistingTranscript] = useState(false);

  const {
    userTranscripts,
    getTranscriptsByUserId,
    loadingNewTranscripts,
    hasMore,
  } = useTranscriptsByUserId();

  const exemptedKeys = [
    "recording_id",
    "id",
    "createdAt",
    "aiEnhancedTranscript",
  ];

  const updateText = useCallback((key, newText) => {
    setTranscriptNote((prevNote) => ({
      ...prevNote,
      [key]: newText,
    }));
  }, []);

  const getNotes = async (recordingId) => {
    if (recordingId) {
      const collectionRef = collection(db, "soap");
      const queryRef = query(
        collectionRef,
        where("recording_id", "==", recordingId)
      );

      const querySnapshot = await getDocs(queryRef);

      console.log("THE QUERY SNAPSHOT", querySnapshot);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const notesObject = doc.data();
          console.log("THE NOTES OBJECT ===>", notesObject);
          const result = Object.keys(notesObject)
            .filter((key) => !exemptedKeys.includes(key))
            .map((key) => ({ name: key, value: notesObject[key] }));
          console.log("THE RESULT  ===>", result);
          setUserExampleSoap(result);
          setTranscriptNote(notesObject);
        });
        return true;
      } else {
        console.log("THERE IS NO SOAP WITH THE RECORDING ID", recordingId);
        return false;
      }
    }
  };

  const getTranscript = async (id) => {
    console.log("Get transcript called");
    if (id) {
      const collectionRef = collection(db, "transcript");
      const queryRef = query(collectionRef, where("recording_id", "==", id));
      const querySnapshot = await getDocs(queryRef);

      // console.log("getTranscript - Query snapshot", querySnapshot.size);

      querySnapshot.forEach((doc) => {
        console.log(doc.id, " Transcript => ", doc.data());
        setTranscript(doc.data());
      });
    } else {
      console.log("No id found");
    }
  };

  const { getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "audio/*": [".mp3", ".mp4", ".wav", ".m4a"], // Updated to accept mp3 files
    },
    onDrop: async (acceptedFiles) => {
      console.log("THE ACCEPTED FILES", acceptedFiles);

      setUploadedRecord(acceptedFiles);

      const originalBlob = acceptedFiles[0];
      console.log("THE ORIGINAL BLOB", originalBlob);
      const createdBlobUrl = URL.createObjectURL(originalBlob);

      console.log("The BlobUrl", createdBlobUrl);
      setBlobURL(createdBlobUrl);

      // Determine the file extension based on the MIME type
      let fileExtension;
      switch (originalBlob.type) {
        case "audio/mpeg":
          setContentType("audio/mp3");
          fileExtension = ".mp3";

          break;
        case "video/mp4":
          setContentType("video/mp4");
          fileExtension = ".mp4";
          break;
        case "audio/wav":
          setContentType("audio/wav");
          fileExtension = ".wav";
          break;
        case "audio/x-m4a":
          setContentType("audio/x-m4a");
          fileExtension = ".m4a";
          break;
        default:
          console.error("Unsupported audio type:", originalBlob.type);
          return;
      }

      const fileName = `${
        auth?.currentUser?.uid
      }/recording_${Date.now()}${fileExtension}`;

      console.log("NEW-FILE-NAME", fileName);

      // Creating a new File from the original blob data
      const newFile = new File([originalBlob], fileName, {
        type: originalBlob.type,
      });

      console.log("THE NEW FILE", newFile);

      setFile(newFile);
      setShowUploadBtn(true);
    },
  });

  const handleRecordingAudioUpload = () => {
    setIsLoading(true);
    setShowUploadAudio(false);
    if (file.size > 50 * 1024 * 1024) {
      toast("The file size is more than 50MB.", { type: "error" });
      setIsLoading(false);
      return;
    }
    const params = {
      Bucket: "vet-appt-recordings",
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };

    console.log("THE PARAMS ===>", params);

    s3.upload(params, function (err, data) {
      console.log("THE ERRORS ====>", err);
      console.log("THE DATA ====>", data);
      if (err) {
        console.log("Error uploading data: ", err);
      } else {
        console.log("WHAT IS GOING ON SELFFFF");
        console.log(`File uploaded successfully at ${data.Location}`);
      }
    }).send(async (err, data) => {
      try {
        console.log("S3 upload done. Starting on iniating transcription...");

        const docRef = await addDoc(collection(db, "recordings"), {
          title: "EXAMPLE TEST NOTE",
          id: uuidv4(),
          record_key: data.Key,
          userId: auth?.currentUser?.uid,
          createdAt: new Date(),
        });

        console.log("Uploaded recording");

        const docSnap = await getDoc(doc(db, "recordings", docRef.id));

        // Make a POST request
        const transcriptionBody = JSON.stringify({
          fileName: docSnap.data().record_key,
          recordId: docSnap.data().id,
          userId: auth?.currentUser?.uid,
          templateId: templateId,
        });

        console.log("transcriptionBody: ", transcriptionBody);

        console.log("CALLING transcription api endpoint");

        const response = await fetch(`${selected}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: transcriptionBody,
        });
        console.log("Transcription api endpoint has been called", response);

        console.log("the recording ID ====>", docSnap.data().id);

        await getTranscript(docSnap.data().id);
        setShowUploadBtn(false);
        setIsLoading(false);

        toast("Sample Soap Created", { type: "success" });

        setBlobURL(null);
        setUploadedRecord(null);

        setRecordingId(docSnap.data().id);
        // fetch transcript for soap note here.
      } catch (error) {
        setShowUploadAudio(true);

        console.error("Error:", error);
        setShowUploadBtn(false);
      }
    });
  };

  const deleteFile = () => {
    setFile(null);
    setBlobURL(null);
    setUploadedRecord(null);
  };

  const getUserSections = async (newTemplateId) => {
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);
    console.log("USER SNAP OF TEMPLATES --->", userSnap);
    if (userSnap.exists()) {
      const data = userSnap.data();
      console.log("THE DATA --->", data);
      const template = data.templates.find(
        (template) => template.id === newTemplateId
      );
      if (template) {
        setUserAddedSection(template.sections);
      } else {
        console.log("No such template!");
      }
    } else {
      console.log("No such document!");
      return null;
    }
  };

  const getUserExamples = async (newTemplateId) => {
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const data = userSnap.data();
      const template = data.templates.find(
        (template) => template.id === newTemplateId
      );
      if (template) {
        setUserAddedExamples(template.examples);
      } else {
        console.log("No such template!");
        return null;
      }
    } else {
      console.log("No such document!");
      return null;
    }
  };

  async function createOrUpdateTemplateSection() {
    setIsLoading(true);

    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);
    const newTemplateId = uuidv4();
    if (!userSnap.exists()) {
      await setDoc(userRef, {
        templates: [
          {
            id: newTemplateId,
            name: templateName,
            isDefault: true,
            sections: [
              {
                section: joinWords(sectionName),
                description: sectionDescription,
              },
            ],
            examples: [],
          },
        ],
      });
      setTemplateId(newTemplateId);
      await getUserSections(newTemplateId);
    } else {
      const data = userSnap.data();
      const templateIndex = data.templates.findIndex(
        (template) =>
          template.name?.toLowerCase() === templateName?.toLowerCase()
      );
      if (templateIndex !== -1) {
        if (
          !data.templates[templateIndex].sections.find(
            (section) =>
              splitWords(section.section)?.toLowerCase() ===
              sectionName?.toLowerCase()
          )
        ) {
          data.templates[templateIndex].sections.push({
            section: joinWords(sectionName),
            description: sectionDescription,
          });
          await updateDoc(userRef, data);
          await getUserSections(templateId);
        }
      } else {
        const newTemplateId = uuidv4();
        await updateDoc(userRef, {
          templates: arrayUnion({
            id: newTemplateId,
            name: templateName,
            isDefault: false,
            sections: [
              {
                section: joinWords(sectionName),
                description: sectionDescription,
              },
            ],
            examples: [],
          }),
        });
        setTemplateId(newTemplateId);
        await getUserSections(newTemplateId);
      }
    }

    setSectionName("");
    setSectionDescription("");
    setIsLoading(false);
  }

  async function createOrUpdateExample() {
    setIsLoading(true);
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);

    const updatedExample = Object.keys(transcriptNote).reduce((obj, key) => {
      if (!exemptedKeys.includes(key)) {
        obj[key] = transcriptNote[key];
      }
      return obj;
    }, {});

    if (!userSnap.exists()) {
      const newTemplateId = uuidv4();
      await setDoc(userRef, {
        templates: [
          {
            id: newTemplateId,
            name: templateName,
            isDefault: true,
            sections: [],
            examples: [
              {
                id: uuidv4(),
                audioKey: file?.name || "",
                transcript: transcript?.transcript || selectedTranscript,
                ...updatedExample,
              },
            ],
          },
        ],
      });
      setTemplateId(newTemplateId);
      getUserExamples(newTemplateId);
      setUserExampleSoap([]);
      setIsLoading(false);
    } else {
      const data = userSnap.data();
      const templateIndex = data.templates.findIndex(
        (template) =>
          template.name.toLowerCase() === templateName?.toLowerCase()
      );
      if (templateIndex !== -1) {
        data.templates[templateIndex].examples.push({
          id: uuidv4(),
          audioKey: file?.name || "",
          transcript:
            transcript?.transcript || selectedTranscript || uploadedTranscript,
          ...updatedExample,
        });
        await updateDoc(userRef, data);
        getUserExamples(templateId);
        setUserExampleSoap([]);
        setIsLoading(false);
        setTranscript("");
        setTemplateName("");
        setTemplateId(null);
        setUserAddedExamples();
        setUserAddedSection();
        setCurrentPage(0);
        setSelectedTranscript();
        setUploadedTranscript("");
        setSelectedTranscriptRecordId();
        setUseExistingTranscript(false);

        setShowUploadAudio(true);
        await getUserTemplates(auth?.currentUser?.uid);
        setNewSectionModal(false);
      } else {
        const newTemplateId = uuidv4();
        await updateDoc(userRef, {
          templates: arrayUnion({
            id: newTemplateId,
            name: templateName,
            isDefault: false,
            sections: [],
            examples: [
              {
                id: uuidv4(),
                audioKey: file.name,
                transcript:
                  transcript?.transcript ||
                  selectedTranscript ||
                  uploadedTranscript,
                ...updatedExample,
              },
            ],
          }),
        });
        getUserExamples(newTemplateId);
        setUserExampleSoap([]);
        setIsLoading(false);
        setTranscript("");
        setTemplateName("");
        setTemplateId(null);
        setUserAddedExamples();
        setUserAddedSection();
        setCurrentPage(0);
        setSelectedTranscript();
        setUploadedTranscript("");
        setSelectedTranscriptRecordId();
        setUseExistingTranscript(false);
        setShowUploadAudio(true);
        await getUserTemplates(auth?.currentUser?.uid);
        setNewSectionModal(false);
      }
    }

    setFile(null);
    setTranscriptNote();
    setUserExampleSoap([]);
  }

  async function addPromptToTemplate() {
    setIsLoading(true);
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const data = userSnap.data();
      const templateIndex = data.templates.findIndex(
        (template) => template.id === templateId
      );

      if (templateIndex !== -1) {
        // Add the prompt key-value pair to the template
        data.templates[templateIndex].prompt = gptSystemMessage;
        // Update the document in the database
        await updateDoc(userRef, data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log(`Template "${templateId}" not found`);
      }
    } else {
      setIsLoading(false);
      console.log(`User "${auth?.currentUser?.uid}" not found`);
    }
  }

  async function createTemplateFromClone(sectionsArray) {
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);
    const newTemplateId = uuidv4();
    if (!userSnap.exists()) {
      await setDoc(userRef, {
        templates: [
          {
            id: newTemplateId,
            name: templateName,
            isDefault: true,
            sections: sectionsArray,
            examples: [],
          },
        ],
      });
      setTemplateId(newTemplateId);
      await getUserSections(newTemplateId);
    } else {
      const data = userSnap.data();
      const templateIndex = data.templates.findIndex(
        (template) =>
          template.name?.toLowerCase() === templateName?.toLowerCase()
      );
      if (templateIndex !== -1) {
        sectionsArray.forEach((section) => {
          if (
            !data.templates[templateIndex].sections.find(
              (existingSection) =>
                splitWords(existingSection.section)?.toLowerCase() ===
                section.section?.toLowerCase()
            )
          ) {
            data.templates[templateIndex].sections.push(section);
          }
        });
        await updateDoc(userRef, data);
        await getUserSections(templateId);
      } else {
        const newTemplateId = uuidv4();
        await updateDoc(userRef, {
          templates: arrayUnion({
            id: newTemplateId,
            name: templateName,
            isDefault: false,
            sections: sectionsArray,
            examples: [],
          }),
        });
        setTemplateId(newTemplateId);
        await getUserSections(newTemplateId);
      }
    }
  }

  const cloneSectionToNewTemplate = async () => {
    setCloneSectionLoading(true);

    const template = userTemplates.find(
      (template) => template.id === selectedTemplate
    );

    const sectionsArray = template ? template.sections : [];

    if (sectionsArray?.length > 0) {
      await createTemplateFromClone(sectionsArray);
      setCloneSectionLoading(false);
    } else {
      setCloneSectionLoading(false);
      return;
    }
  };

  const getTemplates = async (userId) => {
    const userDocRef = doc(db, "user-templates", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      setUserTemplates(userDoc.data()?.templates);
    } else {
      console.log("No such document!");
      return null;
    }
  };

  async function deleteTemplateSection(sectionName) {
    const userId = auth?.currentUser?.uid;
    const userRef = doc(db, "user-templates", userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const data = userSnap.data();
      console.log("deleteTemplateSection >", data);
      const templateIndex = data.templates.findIndex(
        (template) => template.id === templateId
      );

      if (templateIndex !== -1) {
        const sectionIndex = data.templates[templateIndex].sections.findIndex(
          (section) => section.section === sectionName
        );

        if (sectionIndex !== -1) {
          // Remove the section from the sections array
          data.templates[templateIndex].sections.splice(sectionIndex, 1);

          // Update the document in the database
          await updateDoc(userRef, data);
          await getUserSections(templateId);
        } else {
          console.log(
            `Section "${sectionName}" not found in template "${templateId}"`
          );
        }
      } else {
        console.log(`Template "${templateId}" not found`);
      }
    } else {
      console.log(`User "${userId}" not found`);
    }
  }
  async function editTemplateSection() {
    setEditLoading(true);
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      console.log("editTemplateSection >", userData);
      const templateIndex = userData?.templates.findIndex(
        (template) => template.id === templateId
      );

      console.log("editTemplateSection: Template index >", templateIndex);

      if (templateIndex !== -1) {
        const sections = userData.templates[templateIndex].sections;
        console.log("editTemplateSection: Sections  >", sections);

        const sectionIndex = sections.findIndex(
          (section) =>
            joinWords(section.section) === joinWords(originalSectionName)
        );
        console.log("editTemplateSection: sectionIndex  >", sectionIndex);
        if (sectionIndex !== -1) {
          // Add the updated section
          const updatedSection = {
            section: joinWords(sectionName),
            description: sectionDescription,
          };

          // Construct the update payload to only update the specific section
          const updatedSections = sections.map((section, index) =>
            index === sectionIndex ? updatedSection : section
          );

          // Prepare the update payload
          const updatePayload = {};
          updatePayload[`templates.${templateIndex}.sections`] =
            updatedSections;

          console.log("editTemplateSection: updatePayload >", updatePayload);

          let newTemplates = userData.templates.map((template) => {
            if (template.id === templateId) {
              return {
                ...template,
                sections: updatedSections,
              };
            }
            return template;
          });

          console.log(" The new template ->");

          // Update the sections array in the Firestore document
          await updateDoc(userRef, { templates: newTemplates });

          // Construct the updated section with the new name and description

          // Update the section in the array
          // sections[sectionIndex] = updatedSection;

          // Update the document with the new data
          // await updateDoc(userRef, {
          //   [`templates.${templateIndex}.sections`]: sections,
          // });
          setSectionName("");
          setSectionDescription("");

          // Refresh the sections displayed in the UI
          await getUserSections(templateId);
          setInitiateSectionEdit(false);
        }
      }
    }

    setEditLoading(false);
  }

  const handleEditInstance = (name, description) => {
    setOriginalSectionName(name);
    setSectionName(name);
    setSectionDescription(description);
    setInitiateSectionEdit(true);
  };

  // const handleTranscriptSoapCreation = async (type) => {
  //   setIsLoading(true);

  //   let notesBody;

  //   switch (type) {
  //     case "directTranscriptUpload":
  //       notesBody = JSON.stringify({
  //         userId: auth?.currentUser?.uid,
  //         templateId: templateId,
  //         type: "directTranscriptUpload",
  //         uploadedTranscript: uploadedTranscript,
  //       });
  //       break;
  //     // Add more cases as needed
  //     default:
  //       // Default case if no type matches
  //       notesBody = JSON.stringify({
  //         recordingId: selectedTranscriptRecordId,
  //         userId: auth?.currentUser?.uid,
  //         templateId: templateId,
  //         type: "useExistingTranscript",
  //       });
  //   }

  //   try {
  //     const response = await fetch(
  //       `https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-971db314-20dc-4740-9b94-906c0f27c4c3/notes/soap`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: notesBody,
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const responseData = await response.json();
  //     console.log("SOAP creation response:", responseData);
  //     const result = Object.keys(responseData)
  //       .filter((key) => !exemptedKeys.includes(key))
  //       .map((key) => ({ name: key, value: responseData[key] }));
  //     console.log("THE RESULT  ===>", result);
  //     setUserExampleSoap(result);
  //     setTranscriptNote(responseData);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("Example notes for transcript errors", error);
  //     setIsLoading(false);
  //   }
  // };

  async function getGeneratedSoap(userId, templateId) {
    const userRef = doc(db, "user-templates", userId);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      console.log("No such document!");
      return null;
    } else {
      let templates = docSnap.data().templates;
      for (let i = 0; i < templates.length; i++) {
        if (templates[i].id === templateId) {
          return templates[i].generatedSoap;
        }
      }
    }

    console.log("No such template!");
    return null;
  }

  async function removeGeneratedSoap(userId, templateId) {
    const userRef = doc(db, "user-templates", userId);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      console.log("No such document!");
    } else {
      let templates = docSnap.data().templates;
      for (let i = 0; i < templates.length; i++) {
        if (templates[i].id === templateId) {
          delete templates[i].generatedSoap;
          break;
        }
      }

      await updateDoc(userRef, { templates: templates });
    }
  }
  const handleTranscriptSoapCreation = async (type) => {
    setIsLoading(true);

    let notesBody;

    switch (type) {
      case "directTranscriptUpload":
        notesBody = JSON.stringify({
          request: {
            recording_id: "",
            user_id: auth?.currentUser?.uid,
            template_id: templateId,
            uploaded_transcript: uploadedTranscript,
            type: "directTranscriptUpload",
            file_name: "",
          },
        });
        break;
      // Add more cases as needed
      default:
        // Default case if no type matches
        notesBody = JSON.stringify({
          recordingId: selectedTranscriptRecordId,
          userId: auth?.currentUser?.uid,
          templateId: templateId,
          type: "useExistingTranscript",
        });
    }

    try {
      const response = await fetch(
        `https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-971db314-20dc-4740-9b94-906c0f27c4c3/notes/soap`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: notesBody,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Start polling for the generatedSoap
      const intervalId = setInterval(async () => {
        console.log("ARE YOU BEING CALLLED");
        const generatedSoap = await getGeneratedSoap(
          auth?.currentUser?.uid,
          templateId
        );
        if (generatedSoap) {
          const result = Object.keys(generatedSoap)
            .filter((key) => !exemptedKeys.includes(key))
            .map((key) => ({ name: key, value: generatedSoap[key] }));
          console.log("THE RESULT  ===>", result);
          setUserExampleSoap(result);
          setTranscriptNote(generatedSoap);
          clearInterval(intervalId);
          await removeGeneratedSoap(auth?.currentUser?.uid, templateId);
          setIsLoading(false);
        }
      }, 3000); // Poll every 3 seconds
    } catch (error) {
      console.log("Example notes for transcript errors", error);
      setIsLoading(false);
    }
  };
  const handleSkipExamples = async () => {
    setTranscript("");
    setTemplateName("");
    setTemplateId(null);
    setUserAddedExamples();
    setUserAddedSection();
    setSelectedTranscript();
    setUploadedTranscript("");
    setSelectedTranscriptRecordId();
    setUploadedTranscript("");
    setUseExistingTranscript(false);
    setCurrentPage(0);
    setShowUploadBtn(true);
    await getUserTemplates(auth?.currentUser?.uid);
    setNewSectionModal(false);
  };

  let intervalId = null;

  useEffect(() => {
    if (recordingId) {
      intervalId = setInterval(async () => {
        const querySnapshot = await getNotes(recordingId);

        console.log("THE RETURNED SNAPSHOT", querySnapshot);
        if (querySnapshot) {
          clearInterval(intervalId);
          setRecordingId();
        }
      }, 10000);

      // Clear interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [recordingId]);

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      if (userTranscripts.length === 0) {
        getTranscriptsByUserId(auth?.currentUser?.uid);
      }
      // Fetch transcripts for the user
      getTemplates(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  console.log(" getTranscriptsByUserId  >", userTranscripts);
  console.log(" TEMPLATE ID  >", templateId);

  // PAGES
  const pages = [
    {
      title: "Template Name",
      content: (
        <>
          <div className=" mt-3  pb-4 flex flex-row items-center space-x-6">
            <input
              id="name"
              name="Template Name"
              type="text"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              placeholder="Give your template a name"
              required
              className="block pl-2 pr-3 w-[60%] bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </>
      ),
      onSubmit: createOrUpdateTemplateSection,
    },
    {
      title: "Clone Sections",
      content: (
        <>
          {cloneSectionLoading ? (
            <div className="border border-[#55566bff] shadow rounded-md p-4  w-full mx-auto">
              <div className="animate-pulse flex space-x-4 min-h-[300px]">
                <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-2 bg-slate-700 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className=" mt-3 flex flex-col  ">
              {userAddedSection?.length > 0 ? (
                <div className="border rounded-md px-3 py-3  border-[#2D2D39] min-h-[100px] overflow-scroll bg-[#2F3146] space-x-2 flex flex-wrap">
                  {userAddedSection?.map((section) => {
                    return (
                      <SectionBox
                        name={section.section}
                        currentPage={currentPage}
                        description={section.description}
                        deleteSection={deleteTemplateSection}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="flexflex-row px-6 justify-center w-full">
                  <div className="w-full">
                    <div className="flex flex-row ">
                      <div className="border-b border-gray-200">
                        <p className="text-center text-[14px] font-semibold text-white">
                          Select Template to clone from
                        </p>
                      </div>
                    </div>

                    {userTemplates && (
                      <div className=" w-full">
                        <div className="  flex w-full   flex-row items-center ">
                          <fieldset className="w-[50%] ">
                            <div className="mt-2  divide-y divide-gray-200  border-gray-200">
                              {userTemplates?.map((template, templateIdx) => (
                                <div
                                  key={templateIdx}
                                  className="relative flex items-start py-4"
                                >
                                  <div className="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                      htmlFor={`person-${template.id}`}
                                      className="select-none font-medium text-white"
                                    >
                                      {template.name}
                                    </label>
                                  </div>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      id={`person-${template.id}`}
                                      name={`person-${template.id}`}
                                      type="checkbox"
                                      checked={selectedTemplate === template.id}
                                      onChange={() =>
                                        setSelectedTemplate(template.id)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!userAddedSection && (
                <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <div className="flex flex-row justify-end">
                    {selectedTemplate && (
                      <button
                        type="button"
                        onClick={() => cloneSectionToNewTemplate()}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                      >
                        {cloneSectionLoading ? "Loading..." : "Clone Sections"}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ),
      onSubmit: cloneSectionToNewTemplate,
    },
    {
      title: "Sections",
      content: (
        <>
          <div className=" mt-3 flex flex-col  ">
            <p className="text-[14px] text-left font-bold text-[white]">
              Sections
            </p>

            <div className="border rounded-md px-3 py-3  border-[#2D2D39] min-h-[100px] overflow-scroll bg-[#2F3146] space-x-2 flex flex-wrap">
              {userAddedSection?.length > 0 ? (
                userAddedSection?.map((section) => {
                  return (
                    <SectionBox
                      name={section.section}
                      currentPage={currentPage}
                      description={section.description}
                      deleteSection={deleteTemplateSection}
                      handleEditInstance={handleEditInstance}
                    />
                  );
                })
              ) : (
                <div className="flexflex-row justify-center w-full">
                  <div className="w-full">
                    <div className="flex flex-row justify-center">
                      <p className="text-center text-white ">Add Sections</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {
              // If the user has initiated an edit, show the edit form

              initiateSectionEdit ? (
                <div className="mt-6">
                  <input
                    id="name"
                    name="Section Name"
                    type="text"
                    value={sectionName}
                    onChange={(e) => setSectionName(e.target.value)}
                    placeholder="Section Name"
                    required
                    className="block pl-2 pr-3 w-full  bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />

                  <textarea
                    value={sectionDescription}
                    onChange={(e) => setSectionDescription(e.target.value)}
                    rows={8}
                    placeholder="Give a description or an example of this section (e.g. For prescriptions, list it in bullets like so: Pimobendan, Penicillin, Wormwood)"
                    className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm rounded-md focus:outline-none text-[#fafafaff]`}
                  ></textarea>

                  <div className="flex flex-row justify-end mt-10">
                    {editLoading ? (
                      <button
                        disabled
                        type="button"
                        className="text-white mt-2 mb-6 bg-indigo-600 hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => editTemplateSection()}
                        className="rounded-md mt-2 mb-6 disabled:bg-gray-300  bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Update Section
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="mt-6">
                    <input
                      id="name"
                      name="Section Name"
                      type="text"
                      value={sectionName}
                      onChange={(e) => setSectionName(e.target.value)}
                      placeholder="Section Name"
                      required
                      className="block pl-2 pr-3 w-full  bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <textarea
                      value={sectionDescription}
                      onChange={(e) => setSectionDescription(e.target.value)}
                      rows={8}
                      placeholder="Give a description or an example of this section (e.g. For prescriptions, list it in bullets like so: Pimobendan, Penicillin, Wormwood)"
                      className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm rounded-md focus:outline-none text-[#fafafaff]`}
                    ></textarea>
                  </div>

                  <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {isLoading ? (
                      <button
                        type="button"
                        disabled={true}
                        className=" inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                      >
                        Uploading Section...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                        onClick={() => createOrUpdateTemplateSection()}
                      >
                        Add Section
                      </button>
                    )}
                  </div>
                </>
              )
            }
          </div>
        </>
      ),
      onSubmit: createOrUpdateTemplateSection,
    },
    {
      title: "Prompt",
      content: (
        <>
          <div className=" mt-3 flex flex-col  ">
            <p className="text-[14px] text-left font-bold text-[white]">
              Prompt Message
            </p>

            <div className="mt-6">
              <textarea
                value={gptSystemMessage}
                onChange={(e) => setGptSystemMessage(e.target.value)}
                rows={8}
                placeholder="Enter the prompt message for the AI system... "
                className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm rounded-md focus:outline-none text-[#fafafaff]`}
              ></textarea>
            </div>

            <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse">
              {isLoading ? (
                <button
                  type="button"
                  disabled={true}
                  className=" inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                >
                  Adding Prompt to template
                </button>
              ) : (
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                  onClick={() => addPromptToTemplate()}
                >
                  Update Prompt
                </button>
              )}
            </div>
          </div>
        </>
      ),
      onSubmit: addPromptToTemplate,
    },

    //JOIN THIS UP
    {
      title: "Create Examples",
      content: (
        <>
          <div className=" mt-3 flex flex-col  ">
            <div className="flex flex-row  items-center justify-between">
              <p className="text-[14px]  text-left font-bold text-[white]">
                Examples
              </p>
              <div>
                <div className="flex flex-row justify-end">
                  <button
                    type="button"
                    className="inline-flex w-full mb-4 justify-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white   shadow-sm  sm:w-auto"
                    onClick={handleSkipExamples}
                  >
                    Skip
                  </button>
                </div>

                <div className="flex flex-row items-center space-x-4">
                  <div>
                    <p className="text-[12px] text-left font-md text-[white]">
                      {useExisitingTranscript
                        ? "Upload A New Transcipt"
                        : "Use Past Transcript"}
                    </p>
                  </div>

                  <Switch
                    checked={useExisitingTranscript}
                    onChange={() => {
                      setUseExistingTranscript(!useExisitingTranscript);
                    }}
                    className={classNames(
                      useExisitingTranscript ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        useExisitingTranscript
                          ? "translate-x-4"
                          : "translate-x-0",
                        "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>

            <div>
              {useExisitingTranscript ? (
                <div className="">
                  <p className=" py-4 text-white text-[15px] font-bold">
                    Select a Transcript
                  </p>
                  <div className="">
                    <div>
                      <div className="flex p-4 max-w-[100%] max-h-[200px] bg-[#242536] overflow-y-scroll">
                        <fieldset className="mt-2">
                          <legend className="sr-only">Transcripts</legend>
                          <div className="divide-y divide-gray-600">
                            {userTranscripts?.map(
                              (userTranscript, userTranscriptId) => (
                                <div
                                  key={userTranscriptId}
                                  className="relative flex items-start pb-4 pt-3.5"
                                >
                                  <label
                                    htmlFor={`account-${userTranscript.transcriptId}`}
                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    <div className="min-w-0 flex-1 text-sm leading-6">
                                      <p className="font-medium text-white">
                                        {userTranscript?.noteName}
                                      </p>
                                      <div className=" mt-1 flex items-center flex-row space-x-2">
                                        <ClockIcon className="-mr-0.5 h-4 w-4 text-gray-400" />{" "}
                                        <p className="text-gray-400 font-normal text-[12px] ">
                                          {formatDate(
                                            userTranscript?.createdAt
                                          )}
                                          ,{" "}
                                          {formatTime(
                                            userTranscript?.createdAt
                                          )}
                                        </p>
                                      </div>

                                      <p
                                        id={`account-${userTranscript.transcriptId}-description`}
                                        className="text-white"
                                      >
                                        {sliceTranscripts(
                                          userTranscript.transcript
                                        )}
                                        {"..."}

                                        <span
                                          onClick={() => {
                                            console.log("show transcript");
                                          }}
                                          className="text-indigo-600 font-medium italic cursor-pointer"
                                        >
                                          {" "}
                                          show transcript
                                        </span>
                                      </p>
                                    </div>
                                  </label>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      id={`account-${userTranscript.transcriptId}`}
                                      aria-describedby={`account-${userTranscript.transcriptId}-description`}
                                      name="account"
                                      value={userTranscript.recordingId}
                                      onChange={(e) => {
                                        setSelectedTranscriptRecordId(
                                          e.target.value
                                        );
                                        setSelectedTranscript(
                                          combineTranscripts(
                                            userTranscript.transcript
                                          )
                                        );
                                      }}
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </fieldset>
                      </div>
                      <div className="flex flex-row justify-end mt-2">
                        {loadingNewTranscripts ? (
                          <button
                            disabled
                            type="button"
                            className="text-white mt-2 mb-6 bg-indigo-600 hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800 inline-flex items-center"
                          >
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline w-4 h-4 me-3 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              getTranscriptsByUserId(auth?.currentUser?.uid)
                            }
                            className="rounded-md mt-2 mb-6 bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Load more transcripts
                          </button>
                        )}
                      </div>

                      {selectedTranscriptRecordId ? (
                        <div className="mt-2">
                          <TextArea
                            title="Transcript"
                            bgColor={"gray"}
                            text={selectedTranscript}
                            // update={(newText) => updateText("plan", newText)}
                            style={{ maxHeight: "100px", borderColor: "gray" }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {userExampleSoap?.length > 0 ? (
                        <div>
                          {userExampleSoap?.map((customNote, customId) => {
                            return (
                              <TextArea
                                key={customId}
                                title={splitWords(customNote.name)}
                                bgColor={"blue"}
                                hideIcon={true}
                                text={transcriptNote[customNote.name]}
                                update={(newText) =>
                                  updateText(customNote.name, newText)
                                }
                              />
                            );
                          })}
                          <div className="flex flex-row justify-end">
                            <button
                              disabled={
                                userExampleSoap.length > 0 ? false : true
                              }
                              type="button"
                              className="inline-flex w-full justify-center disabled:bg-gray-300  rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                              onClick={() => createOrUpdateExample()}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row justify-end">
                          {isLoading ? (
                            <button
                              disabled
                              type="button"
                              className="text-white mt-2 mb-6 bg-indigo-600 hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800 inline-flex items-center"
                            >
                              <svg
                                aria-hidden="true"
                                role="status"
                                className="inline w-4 h-4 me-3 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled={
                                selectedTranscriptRecordId ? false : true
                              }
                              onClick={() => handleTranscriptSoapCreation()}
                              className="rounded-md mt-2 mb-6 disabled:bg-gray-300  bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Generate Example
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <textarea
                      value={uploadedTranscript}
                      onChange={(e) => setUploadedTranscript(e.target.value)}
                      rows={8}
                      placeholder="Copy a transcript sample here"
                      className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm rounded-md focus:outline-none text-[#fafafaff]`}
                    ></textarea>

                    <div className="mt-10  ">
                      <div>
                        {userExampleSoap?.length > 0 ? (
                          <div>
                            {userExampleSoap?.map((customNote, customId) => {
                              return (
                                <TextArea
                                  key={customId}
                                  title={splitWords(customNote.name)}
                                  bgColor={"blue"}
                                  hideIcon={true}
                                  text={transcriptNote[customNote.name]}
                                  update={(newText) =>
                                    updateText(customNote.name, newText)
                                  }
                                />
                              );
                            })}
                            <div className="flex flex-row justify-end">
                              <button
                                disabled={
                                  userExampleSoap.length > 0 ? false : true
                                }
                                type="button"
                                className="inline-flex w-full justify-center disabled:bg-gray-300  rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                                onClick={() => createOrUpdateExample()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-row justify-end">
                            {isLoading ? (
                              <button
                                disabled
                                type="button"
                                className="text-white mt-2 mb-6 bg-indigo-600 hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800 inline-flex items-center"
                              >
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 me-3 text-white animate-spin"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled={
                                  uploadedTranscript?.length > 0 ? false : true
                                }
                                onClick={() =>
                                  handleTranscriptSoapCreation(
                                    "directTranscriptUpload"
                                  )
                                }
                                className="rounded-md mt-2 mb-6 disabled:bg-gray-300  bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Generate Example from Transcript
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ),
      onSubmit: createOrUpdateExample,
    },
    // Add more pages here...
  ];

  const nextPage = async () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  console.log("SELECTED RECORDING ID ---->", selectedTranscriptRecordId);

  // console.log("TEMPLATE ID ====>", templateId);
  return (
    <>
      <ToastContainer />
      <Transition.Root show={openNewSectionModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          onClose={async () => {
            setTranscript("");
            setTemplateName("");
            setTemplateId(null);
            setUserAddedExamples();
            setUserAddedSection();
            setSelectedTranscript();
            setUploadedTranscript("");
            setSelectedTranscriptRecordId();
            setUseExistingTranscript(false);
            setCurrentPage(0);
            setShowUploadBtn(true);
            await getUserTemplates(auth?.currentUser?.uid);
            setNewSectionModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-[#191A26] px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[55%] sm:p-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-0 w-full  text-center  sm:mt-0 sm:text-left">
                      <div className=" flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:justify-between sm:items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-[18px] text-left  font-semibold leading-6 text-gray-50"
                        >
                          Create a New Template
                        </Dialog.Title>
                      </div>

                      <div className="mt-6">{pages[currentPage].content}</div>

                      {isLoading || templateName.length < 1 ? (
                        <></>
                      ) : (
                        <div className=" mt-6 flex flex-row justify-start space-x-3">
                          {currentPage > 0 && (
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white   shadow-sm  sm:w-auto"
                              onClick={prevPage}
                            >
                              Back
                            </button>
                          )}

                          {currentPage === 4 ? (
                            <></>
                          ) : (
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white   shadow-sm  sm:w-auto"
                              onClick={nextPage}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
