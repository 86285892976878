import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
export const withAuthorization = (Component) => (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const checkDomain = async (userData, userDocRef) => {
    const hostname = window.location.hostname;
    if (
      hostname === "generalpartnershipai.com" ||
      hostname === "app.generalpartnership.com"
    ) {
      const attioDemo = userData.attioDemo;

      // If attioDemo is not true (or undefined), set it to true
      await updateDoc(userDocRef, { attioDemo: true });
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user?.uid;
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);

        const userData = userDocSnap.data();
        await checkDomain(userData, userDocRef);

        // Check if isBillingDisabled is true and  also if subscription is not cancelled
        // if (
        //   (userDocSnap.exists() && userDocSnap.data().isBillingDisabled) ||
        //   (userDocSnap.exists() &&
        //     userDocSnap.data().subscriptionId &&
        //     !userDocSnap.data().isSubscriptionCancelled)
        // ) {
        //   // If isBillingDisabled is true, navigate to the /dashboard page
        //   setIsLoading(false);
        // } else if (
        //   userDocSnap.exists() &&
        //   userDocSnap.data().isSubscriptionCancelled
        // ) {
        //   navigate("/subscribe");
        //   setIsLoading(false);
        // } else {
        //   navigate("/subscription");
        //   setIsLoading(false);
        // }

        setIsLoading(false);
      } else {
        // User is signed out
        navigate("/sign-in");
        // ...
        setIsLoading(false);
        console.log("user is logged out");
      }
    });
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="lg:h-[100vh]  bg-[#1A1A26] lg:overflow-y-scroll lg:no-scrollbar no-scrollbar"></div>
    );
  }

  return <Component {...props} />;
};
