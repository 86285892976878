import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/dashboard";
import SettingPage from "./pages/setting";
import SignInPage from "./pages/sign-in/sign-in";
import SignUpPage from "./pages/sign-up";
import SubscriptionPage from "./pages/subscription";
import SubscribePage from "./pages/subscribe";
import BillingPage from "./pages/billing";
import TemplatesPage from "./pages/templates";
import SupportPage from "./pages/support";

import SuccessPage from "./pages/success";

import ForgotPasswordPage from "./pages/forgot-password/forgot-password";

import EachNote from "./pages/each-note";
import NotesPage from "./pages/notes/notes";
import { WavAudioProvider } from "./context/wavAudioContext";
import { AttioDemoProvider } from "./context/attioDemo";
import { TrialPeriodProvider } from "./context/trialPeriod";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: "",
      isBlocked: false,
    };
  }

  render() {
    return (
      <div className="App overflow-y-scroll  no-scrollbar">
        <Router>
          <TrialPeriodProvider>
            <WavAudioProvider>
              <AttioDemoProvider>
                <Routes>
                  <Route path="/" element={<DashboardPage />} />
                  <Route path="/sign-in" element={<SignInPage />} />
                  <Route path="/sign-up" element={<SignUpPage />} />
                  <Route path="/subscription" element={<SubscriptionPage />} />
                  <Route path="/subscribe" element={<SubscribePage />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route
                    path="/reset-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/settings" element={<SettingPage />} />
                  <Route path="/templates" element={<TemplatesPage />} />
                  <Route path="/support" element={<SupportPage />} />
                  <Route path="/billing" element={<BillingPage />} />
                  <Route path="/notes" element={<NotesPage />} />
                  <Route path="/notes/:id" element={<EachNote />} />
                </Routes>
              </AttioDemoProvider>
            </WavAudioProvider>
          </TrialPeriodProvider>
        </Router>
      </div>
    );
  }
}

export default App;
