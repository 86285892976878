import {
  HomeIcon,
  Cog6ToothIcon,
  ChatBubbleOvalLeftIcon,
  NewspaperIcon,
  CommandLineIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

export const DashboardItems = (subscriptionActive, attioDemo) => {
  let dashboardItems = [
    {
      name: "Dashboard",
      href: "#",
      icon: HomeIcon,
      current: false,
      key: "/dashboard",
    },
    {
      name: "Notes",
      href: "#",
      icon: NewspaperIcon,
      current: true,
      key: "/notes",
    },
    {
      name: "Settings",
      href: "#",
      icon: Cog6ToothIcon,
      current: false,
      key: "/settings",
    },

    {
      name: "Templates",
      href: "#",
      icon: CommandLineIcon,
      key: "/templates",
    },
  ];

  if (!attioDemo) {
    dashboardItems.push({
      name: "Support",
      href: "#",
      icon: ChatBubbleOvalLeftIcon,
      key: "/support",
    });
  }

  const billingItems = [
    // {
    //   name: "Billing",
    //   href: "#",
    //   icon: BanknotesIcon,
    //   key: "/billing",
    // },
    {
      name: "Subscription",
      href: "#",
      icon: BanknotesIcon,
      key: "/subscription",
    },
  ];

  if (subscriptionActive) {
    return [...dashboardItems, ...billingItems];
  } else {
    return dashboardItems;
  }
};
