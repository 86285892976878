import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { getAuth } from "firebase/auth";
import { db } from "../../firebase-config";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";

export default function SelectTemplateModal(props) {
  const {
    recordingId,
    templates,
    setCustomNotes,
    openSelectTemplateModal,
    setOpenSelectTemplateModal,
    modalTitle,
    modalType,
    getNotes,
    fileName,
    clearState,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [prompt, setPrompt] = useState("");

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleCheckboxChange = (id) => {
    setSelectedTemplate(id);
  };

  const auth = getAuth();

  console.log("TEMPLATESSSS ===>", templates);

  const handleLoadingDisplayMessage = () => {
    switch (modalType) {
      case "FN":
        return "We are currently fixing your note. It may take up to a minute. You can close this modal.";

      case "RDO":
        return "We are currently updating your note. It may take up to a minute. You can close this modal.";

      case "RMN":
        return "We are currently updating your note. It may take up to a minute. You can close this modal.";
      default:
        break;
    }
  };

  const handleNoteRegeneration = async () => {
    switch (modalType) {
      case "FN":
        try {
          setIsLoading(true);
          const regenerateBody = JSON.stringify({ recordingId, prompt });

          await fetch(
            `https://proxy-server-rbn8.onrender.com/medassist-us-regenerate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: regenerateBody,
            }
          );

          await getNotes();
          setIsLoading(false);
          setPrompt("");
          setOpenSelectTemplateModal(false);
        } catch (error) {
          console.error("Error:", error);
        }

        break;

      default:
        try {
          setIsLoading(true);

          const remakeNewNoteBody = JSON.stringify({
            recordId: recordingId,
            fileName: fileName,
            userId: auth?.currentUser?.uid,
            templateId: selectedTemplate,
          });

          console.log("THE DATA BEING CALLED", remakeNewNoteBody);

          fetch(
            `https://proxy-server-rbn8.onrender.com/medassist-us-transcribe`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: remakeNewNoteBody,
            }
          );
          clearState();
          setCustomNotes([]);
          setPrompt("");
        } catch (error) {
          console.error("Error:", error);
        }

        break;
    }
  };

  return (
    <>
      <ToastContainer />
      <Transition.Root show={openSelectTemplateModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenSelectTemplateModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#191A26] px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50%] sm:px-8 sm:py-4">
                  <div className=" flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:justify-between sm:items-center py-6">
                    <Dialog.Title
                      as="h3"
                      className="text-[18px] text-left border-b border-gray-200  font-semibold leading-6 text-gray-50"
                    >
                      {modalTitle}
                    </Dialog.Title>
                  </div>
                  {isLoading ? (
                    <div className="border border-[#55566bff] shadow rounded-md p-4  w-full mx-auto">
                      <div className="animate-pulse flex space-x-4 min-h-[300px]">
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <p className="lg:text-[16px] text-left text-[16px] font-semibold text-white ">
                              {handleLoadingDisplayMessage()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="sm:flex sm:items-start w-full">
                      <div className="mt-0 w-full  text-center  sm:mt-0 sm:text-left">
                        {templates &&
                          (modalType === "RMN" || modalType === "RDO") && (
                            <div className="mt-6">
                              <p className="text-[16px] text-left font-normal text-white">
                                Select Template to use
                              </p>

                              <div className=" flex   flex-row items-center ">
                                <fieldset className="w-[50%]">
                                  <legend className="text-base font-semibold leading-6 text-gray-900">
                                    Templates
                                  </legend>
                                  <div className="mt-2 divide-y divide-gray-200  border-gray-200">
                                    {templates.map((template, templateIdx) => (
                                      <div
                                        key={templateIdx}
                                        className="relative flex items-start py-4"
                                      >
                                        <div className="min-w-0 flex-1 text-sm leading-6">
                                          <label
                                            htmlFor={`person-${template.id}`}
                                            className="select-none font-medium text-white"
                                          >
                                            {template.name}
                                          </label>
                                        </div>
                                        <div className="ml-3 flex h-6 items-center">
                                          <input
                                            id={`person-${template.id}`}
                                            name={`person-${template.id}`}
                                            type="checkbox"
                                            checked={
                                              selectedTemplate === template.id
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(template.id)
                                            }
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          )}

                        <div>
                          {modalType === "FN" && (
                            <div className="mt-3">
                              <p className="text-white text-[16px] font-semibold">
                                Prompt:
                              </p>
                              <textarea
                                disabled={isLoading ? true : false}
                                readOnly={isLoading ? true : false}
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                                rows={8}
                                placeholder="Give a promt for the AI to use. An example of this case is (e.g. Remove all the patient repeated name on the Subjective Section"
                                className={`w-full mt-2 py-2 px-2 bg-[#2F3146] sm:text-sm border-2 rounded-md focus:outline-none text-[#fafafaff]`}
                              ></textarea>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {isLoading ? (
                      <button
                        type="button"
                        // onClick={() => setOpenSelectTemplateModal(false)}
                        // refresh the page on click
                        onClick={async () => {
                          window.location.reload();
                        }}
                        className=" inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                      >
                        Close
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleNoteRegeneration()}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                      >
                        {modalType === "FN"
                          ? "Fix Note"
                          : modalType === "RDO"
                          ? "Redo Note"
                          : "Remake Note"}
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
