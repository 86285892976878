import { Fragment, useState, useCallback, useRef, useEffect } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { DashboardItems } from "../../utils/constant";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

const exemptedKeys = [
  "recording_id",
  "id",
  "audioKey",
  "createdAt",
  "transcript",
  "aiEnhancedTranscript",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const BillingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const auth = getAuth();

  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  async function createBillingSession() {
    try {
      const userId = auth.currentUser?.uid;
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      // Get the customerId from the user document
      const customerId = userDocSnap.data().stripeCustomerId;
      const domain = window.location.origin;

      console.log("THE CUSTOMER ID IS", customerId);
      const response = await fetch(
        "https://vetassist-mobile-backend.onrender.com/create-billing-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customerId, domain }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // This will be the URL of the Stripe Billing Portal session
      const url = data.url;

      // You can redirect the user to this URL
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    if (auth.currentUser.uid) {
      handleUserSubscriptionCheck(auth.currentUser.uid);
    }
  }, [auth.currentUser]);
  return (
    <>
      <div className="bg-[#1f2838ff]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-12 w-auto"
                        src="/images/MedAssist-white.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive)?.map((item) => (
                              <li
                                key={item.name}
                                onClick={() => {
                                  navigate(item.key);
                                }}
                              >
                                <a
                                  className={classNames(
                                    item.key === location.pathname
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center py-12">
              <img
                className="h-12 w-auto"
                src="/images/MedAssist-white.svg"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive)?.map((item) => (
                      <li
                        key={item.name}
                        onClick={() => {
                          navigate(item.key);
                        }}
                      >
                        <a
                          className={classNames(
                            item.key === location.pathname
                              ? "bg-indigo-600 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            {/* <img
              src="/images/MedAssist-white.svg"
              alt=""
              className="flex-shrink-0 lg:hidden "
            /> */}

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              <img
                src="/images/pet.svg"
                alt=""
                className="h-[32px w-[32px] flex-shrink-0 rounded-full"
              />
            </div>
          </div>

          <main className="lg:h-[100vh] min-h-[100vh] flex-1 bg-[#1A1A26] overflow-y-scroll">
            <div className="hidden lg:block  lg:no-scrollbar lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 py-8  ">
              <h1 className="text-left text-[30px] font-bold text-white">
                Billing
              </h1>
            </div>
            <div className=" px-4 sm:px-6 lg:px-8 py-10  ">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-base text-left font-semibold leading-6 text-gray-900">
                    Manage subscription
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-left text-gray-500">
                    <p>
                      You are currently on the{" "}
                      <span className="font-semibold">Premium</span> plan. You
                      can change or cancel your plan anytime.
                    </p>
                  </div>
                  <div className="mt-5 flex flex-row justify-start">
                    <button
                      type="button"
                      onClick={() => {
                        createBillingSession();
                      }}
                      className="inline-flex self-start items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Change plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(BillingPage);
