import { db } from "../../firebase-config";
import { doc, getDoc } from "firebase/firestore";

export const getStevenTemplates = async () => {
  const userRef = doc(db, "user-templates", "AQsjply2mRSyX2Roq3DN8jDyNUM2");
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    console.log("User templates:", userSnap.data()?.templates);
    return userSnap.data()?.templates;
  } else {
    console.log(`No templates found for user`);
  }
};
