import { Fragment, useState, useCallback, useRef, useEffect } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { XMarkIcon, UserIcon } from "@heroicons/react/24/outline";
import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { DashboardItems } from "../../utils/constant";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { ToastContainer } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SupportPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const auth = getAuth();

  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      handleUserSubscriptionCheck(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  return (
    <>
      <ToastContainer autoClose />

      <div className="bg-[#1f2838ff]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/images/MedAssist-white.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive)?.map((item) => (
                              <li
                                key={item.name}
                                onClick={() => {
                                  navigate(item.key);
                                }}
                              >
                                <a
                                  className={classNames(
                                    item.key === location.pathname
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center py-12">
              <img
                className="h-12 w-auto"
                src="/images/MedAssist-white.svg"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive)?.map((item) => (
                      <li
                        key={item.name}
                        onClick={() => {
                          navigate(item.key);
                        }}
                      >
                        <a
                          className={classNames(
                            item.key === location.pathname
                              ? "bg-indigo-600 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            <img
              src="/images/MedAssist-white.svg"
              alt=""
              className="flex-shrink-0 lg:hidden h-12"
            />

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              <UserIcon
                onClick={() => navigate("/settings")}
                color="white"
                className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden "
              />
            </div>
          </div>

          <main className="min-h-screen flex-1 bg-[#1A1A26] overflow-y-scroll">
            <div className="px-4 sm:px-6 lg:px-8 py-8">
              <h1 className="lg:text-left text-center text-2xl sm:text-3xl lg:text-[30px] font-bold text-white">
                Support
              </h1>
              <div className="mt-4 flex flex-col space-y-4">
                <div className="flex flex-col sm:flex-row justify-start space-y-2 sm:space-y-0 sm:space-x-2">
                  <p className="text-white text-sm sm:text-base">
                    For any issue or questions, email
                  </p>
                  <a
                    href="mailto:cmontefering@hellomedassist.com"
                    className="break-all"
                  >
                    <p className="text-white text-sm sm:text-base font-bold">
                      cmontefering@hellomedassist.com
                    </p>
                  </a>
                  <p className="text-white text-sm sm:text-base">
                    or call/text
                  </p>
                  <a href="tel:+17075639783" className="break-all">
                    <p className="text-white text-sm sm:text-base font-bold">
                      +1 (707) 563-9783
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(SupportPage);
