import { useState } from "react";

import { CheckIcon } from "@heroicons/react/20/solid";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const pricing = {
  frequencies: [{ value: "monthly", label: "Monthly", priceSuffix: "/ month" }],
  tiers: [
    {
      name: "MedAssist Web & Mobile App",
      id: "tier-enterprise",
      href: "#",
      price: { monthly: "$179.00" },
      description: "The most advanced medical note creation software.",
      features: [
        "Unlimited SOAP & EMR Notes",
        "Unlimited Recordings",
        "Templates for all medical & vetinarian specialties",
        "Templates for all major vet EHRs (ezyVet, Avimark, and much more!)",
        "Templates for all major medical EHRs (Kareo, DrChrono, Epic, Cerner, and much more!)",
        "Dedicated support from our team to help you create custom templates for your practice",
        "Analytics for Unlimited Note Templates",
        "Dedicated customer support time for any issues",
        "AI-powered transcription",
        "AI-powered SOAP note and EHR form-fills generation",
        "AI-powered editing of notes",
        "Customizable note templates fit for your practice & EHR",
      ],
      mostPopular: true,
      lookup_key: "price_1P45OQLGVk1TtExG5QCkiALN",
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubscribePage() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscription = async (lookup_key) => {
    setIsLoading(true);
    const userId = auth.currentUser.uid;

    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      const customerId = userDocSnap.data().stripeCustomerId;
      const isSubscriptionCancelled =
        userDocSnap.data().isSubscriptionCancelled;
      const domain = window.location.origin;
      console.log("YOOOOOO", {
        customerId: customerId,
        priceId: lookup_key,
        domain: domain,
        isSubscriptionCancelled: isSubscriptionCancelled ? true : false,
      });

      const response = await fetch(
        "https://vetassist-mobile-backend.onrender.com/create-subscription-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: customerId,
            priceId: lookup_key,
            domain: domain,
            isSubscriptionCancelled: isSubscriptionCancelled ? true : false,
          }),
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsLoading(false);

      // Redirect to the session URL
      window.location.href = data.url;
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/sign-in");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className="bg-white py-10">
      <main>
        {/* Pricing section */}
        <div className="mx-auto  max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
              Restart Your MedAssist Plan
            </h1>
          </div>
          {/* TODO: bold the $6/day */}
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Restart your access to MedAssist for $179.00/month.
          </p>

          <div className="flex flex-row md:gap-10 mt-10  justify-center">
            {pricing.tiers.map((tier, tierId) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-indigo-600"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-8 cursor-pointer "
                )}
              >
                <h2
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex flex-row justify-center items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                </p>

                <a
                  aria-describedby={tier.id}
                  onClick={() => {
                    handleSubscription(tier.lookup_key);
                  }}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                      : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                    "mt-6 flex flex-row justify-center  rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                >
                  {isLoading ? (
                    <div className="w-4 h-4 border-2 border-t-2 border-white rounded-full animate-spin"></div>
                  ) : (
                    "Subscribe"
                  )}
                </a>
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <a
              // aria-describedby={tier.id}
              onClick={() => {
                handleSignOut();
              }}
              className={classNames(
                // tier.mostPopular
                //   ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                "mt-6 flex flex-row justify-center  rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
              style={{ minWidth: 150, maxWidth: 200 }}
            >
              {isLoading ? (
                <div className="w-4 h-4 border-2 border-t-2 border-white rounded-full animate-spin"></div>
              ) : (
                "Log out"
              )}
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
