import {
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  UserIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import { TextArea } from "../../component/TextArea/TextArea";
import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  getDocs,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import AWS from "aws-sdk";
import {
  formatTime,
  formatDate,
  splitWords,
} from "../../utils/helper-functions";
import { DashboardItems } from "../../utils/constant";
import toast, { Toaster } from "react-hot-toast";

import NewNoteModal from "../../component/Rename";
import SelectTemplateModal from "../../component/SelectTemplate";
import { getAuth } from "firebase/auth";
import { FaMagic } from "react-icons/fa";
import { AttioDemoContext } from "../../context/attioDemo";

const spacesEndpoint = new AWS.Endpoint("sfo2.digitaloceanspaces.com");
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO007HAKLKL49L67EFVP",
  secretAccessKey: "td9Qp547sxcrnMezrxUFH4hpiHtJekyWpT8DPEOJ9bA",
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const defaultSoapNote = [
  "recording_id",
  "id",
  "createdAt",
  "aiEnhancedTranscript",
];

const DEFAULT_MODAL_TYPE = {
  // Redo Note
  RDO: "RDO",
  // Remake Note
  RMN: "RMN",
  //Fix Note
  FN: "FN",
};
const order = ["subjective", "objective", "assessment", "plan"];
const EachNotePage = () => {
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { id } = useParams();
  const [newNotes, setNewNotes] = useState("Medical Record");
  const [openNewNoteModal, setOpenNewNoteModal] = useState(false);
  const [transcript, setTranscript] = useState("");
  // TODO(@Precious): Rename this to soap note
  const [transcriptNote, setTranscriptNote] = useState();
  const [recordingUrl, setRecordingUrl] = useState("");
  const [recordingData, setRecordingData] = useState();
  const [customNotes, setCustomNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userHasEdited, setUserHasEdited] = useState(false);
  const [userTemplates, setUserTemplates] = useState();
  const [openSelectTemplateModal, setOpenSelectTemplateModal] = useState(false);
  const [selectTemplateModalTitle, setSelectTemplateModalTitle] = useState("");
  const [selectTemplateType, setSelectTemplateType] = useState("");
  const [fileName, setFileName] = useState("");
  const [attioJsonLoading, setAttioJsonLoading] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  const getNotes = async () => {
    if (id) {
      const collectionRef = collection(db, "soap");
      const recordingCollection = collection(db, "recordings");
      const queryRef = query(collectionRef, where("recording_id", "==", id));
      const recordingQuery = query(recordingCollection, where("id", "==", id));
      const recordingSnapshot = await getDocs(recordingQuery);

      const querySnapshot = await getDocs(queryRef);

      if (!recordingSnapshot.empty) {
        recordingSnapshot.forEach((doc) => {
          setFileName(doc.data().record_key);
          console.log("THE RECORDING DATA", doc?.data().record_key);
        });
      }

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const notesObject = doc.data();
          const result = Object.keys(notesObject)
            .filter((key) => !defaultSoapNote.includes(key))
            .map((key) => ({ name: key, value: notesObject[key] }));
          console.log("THE RESULT  ===>", result);
          setCustomNotes(result);
          console.log("THE NOTE OBJECT --->", notesObject);
          setTranscriptNote(notesObject);
          setIsLoading(false);
        });
      }
    }
  };

  const getTemplates = async (userId) => {
    const userDocRef = doc(db, "user-templates", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      setUserTemplates(userDoc.data()?.templates);
    } else {
      console.log("No such document!");
      return null;
    }
  };

  const getTranscript = async () => {
    console.log("Get transcript called");
    if (id) {
      const collectionRef = collection(db, "transcript");
      const queryRef = query(collectionRef, where("recording_id", "==", id));
      const querySnapshot = await getDocs(queryRef);

      // console.log("getTranscript - Query snapshot", querySnapshot.size);

      querySnapshot.forEach((doc) => {
        console.log(doc.id, " Transcript => ", doc.data());
        setTranscript(doc.data());
      });
    } else {
      console.log("No id found");
    }
  };

  const getRecording = async () => {
    if (id) {
      const collectionRef = collection(db, "recordings");
      const queryRef = query(collectionRef, where("id", "==", id));
      const querySnapshot = await getDocs(queryRef);

      querySnapshot.forEach((doc) => {
        // console.log(doc.id, " Recording => ", doc.data());
        setRecordingData(doc.data());

        s3.getSignedUrl(
          "getObject",
          {
            Bucket: "vet-appt-recordings",
            Key: doc.data().record_key,
            Expires: 1800,
          },
          (err, url) => {
            if (err) {
              console.log("Error generating presigned URL", err);
              return;
            }
            setRecordingUrl(url);
            // console.log("Presigned URL:", url);
            // Use the URL for your needs here
          }
        );
      });
    }
  };

  const updateDbNotes = async () => {
    const collectionRef = collection(db, "soap");
    const queryRef = query(collectionRef, where("recording_id", "==", id));
    const querySnapshot = await getDocs(queryRef);

    // const

    querySnapshot.forEach((doc) => {
      // toast.dismiss();
      setDoc(doc.ref, transcriptNote);
      // toast("SOAP note updated", { type: "success", autoClose: 100 });
    });
  };

  useEffect(() => {
    console.log("Use effect called");
    getTranscript();
    getNotes();
    getRecording();
  }, [id]);

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      getTemplates(auth?.currentUser?.uid);
      handleUserSubscriptionCheck(auth.currentUser.uid);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (userHasEdited) {
      updateDbNotes();
    }
  }, [transcriptNote, userHasEdited]);

  const updateText = useCallback((key, newText) => {
    setTranscriptNote((prevNote) => ({
      ...prevNote,
      [key]: newText,
    }));
    setUserHasEdited(true);
  }, []);

  function sortNotes(notes) {
    return notes.sort((a, b) => {
      const aIndex = order.indexOf(a.name.toLowerCase());
      const bIndex = order.indexOf(b.name.toLowerCase());

      if (aIndex === -1 && bIndex === -1) return 0; // both elements are not in the order array, keep their relative order
      if (aIndex === -1) return 1; // a is not in the order array, it should come after
      if (bIndex === -1) return -1; // b is not in the order array, it should come after

      return aIndex - bIndex; // both elements are in the order array, sort them accordingly
    });
  }

  const openTemplateModal = (type, title) => {
    setSelectTemplateModalTitle(title);
    setSelectTemplateType(type);
    setOpenSelectTemplateModal(true);
  };

  console.log("THE PATH NAME -->", transcript);

  function combineTranscripts(transcripts) {
    console.log("THE TRANSCRIPT", transcripts);
    if (typeof transcripts === "string") {
      return transcripts;
    }

    let combined = "";
    if (transcripts === undefined) {
      return combined;
    }

    transcripts.forEach((transcript) => {
      if (transcript.transcript !== null) {
        combined += `${transcript.name}:\n${transcript.transcript}\n\n`;
      }
    });

    return combined;
  }

  const handleAttioPost = async () => {
    setAttioJsonLoading(true);
    try {
      const response = await fetch(
        "https://proxy-server-rbn8.onrender.com/post_attio",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer sk_prod_qrdbYPEsgrzeeFVebd1tt+/qe8li8Xq+RYJkViHztCE=",
          },
          body: JSON.stringify({
            request: {
              recording_id: id,
              user_id: auth?.currentUser?.uid,
            },
          }),
        }
      );

      if (!response.ok) {
        setAttioJsonLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAttioJsonLoading(false);
      toast.success("JSON added to CRM");

      // If you need to use the response, you can parse it here
      const data = await response.json();
      console.log("THE DATA FROM ATTIO", data);
    } catch (error) {
      setAttioJsonLoading(false);
      toast.error("error occured while adding JSON to CRM");

      console.error("An error occurred while making the request:", error);
    }
  };

  const clearCurrentState = () => {
    setIsLoading(true);
    setTranscript();
  };

  // console.log("THE TRANSCRIPT ---->", transcript);

  // function
  return (
    <>
      <NewNoteModal
        newNotes={newNotes}
        setNewNotes={setNewNotes}
        recordingId={id}
        openNewNoteModal={openNewNoteModal}
        setOpenNewNoteModal={setOpenNewNoteModal}
        isDashboard={false}
        getRecording={getRecording}
      />

      <SelectTemplateModal
        recordingId={id}
        templates={userTemplates}
        openSelectTemplateModal={openSelectTemplateModal}
        setOpenSelectTemplateModal={setOpenSelectTemplateModal}
        modalTitle={selectTemplateModalTitle}
        modalType={selectTemplateType}
        setCustomNotes={setCustomNotes}
        clearState={clearCurrentState}
        getNotes={getNotes}
        fileName={fileName}
      />
      <Toaster />
      <div className="bg-[#1f2838ff]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto "
                        src={
                          attioDemo
                            ? "/images/the_general_partnership_cover.jpeg"
                            : "/images/MedAssist-white.svg"
                        }
                        // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive, attioDemo)?.map(
                              (item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    navigate("/settings");
                                  }}
                                >
                                  <a
                                    className={classNames(
                                      location.pathname.includes(item.key)
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            {attioDemo ? (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto  "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            ) : (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto"
                  src="/images/MedAssist-white.svg"
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            )}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive, attioDemo)?.map(
                      (item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            navigate(item.key);
                          }}
                        >
                          <a
                            className={classNames(
                              location.pathname.includes(item.key)
                                ? "bg-indigo-600 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky top-0 z-40 flex justify-between lg:h-5 h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            {attioDemo ? (
              <div className="flex  shrink-0 items-center ">
                {/* <img
                  className="h-8 w-auto lg:hidden"
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                /> */}
              </div>
            ) : (
              <img
                src="/images/MedAssist-white.svg"
                alt=""
                className="flex-shrink-0 lg:hidden lg:hidden h-12"
              />
            )}

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              <UserIcon
                onClick={() => navigate("/settings")}
                color="white"
                className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden h-12"
              />
            </div>
          </div>

          <main className="lg:h-[100vh] min-h-[100vh] flex-1 bg-[#1A1A26] overflow-y-scroll">
            {/* Title of the Dashboard */}
            <div className="hidden lg:flex flex-row items-center lg:space-x-10 lg:space-y-0 space-x-4 px-4 sm:px-6 lg:px-8 pb-2">
              <img
                src="/images/arrow-left.svg"
                className="w-[32px] h-[27px] cursor-pointer"
                onClick={() => navigate("/dashboard")}
              />

              {/* <p className="text-left lg:text-[48px] text-[24px] font-bold text-white">
                Edit Notes
              </p> */}
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-4">
              <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4 lg:justify-between lg:mb-4">
                <div className="flex flex-row items-center space-x-5 lg:space-x-6">
                  <p className="lg:text-[30px] text-left text-[28px] font-semibold text-white ">
                    {recordingData?.title
                      ? recordingData?.title
                      : "Unnamed Note"}
                  </p>

                  {/* <button
                    type="button"
                    onClick={() => {
                      setNewNotes(
                        recordingData?.title
                          ? recordingData?.title
                          : "Medical Record"
                      );
                      setOpenNewNoteModal(true);
                    }}
                    className="inline-flex lg:h-[30px] h-[25px] items-center cursor-pointer  justify-center gap-x-2 rounded-2xl bg-[#F34CA6] px-4 py-2.5 text-[16px] font-semibold text-gray-200 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Rename
                    <img
                      src="/images/rotate-right.svg"
                      className="-mr-0.5 h-4 w-4"
                      aria-hidden="true"
                    />
                  </button> */}

                  {/* {attioDemo && (
                    <button
                      type="button"
                      onClick={handleAttioPost}
                      isLoading={attioJsonLoading}
                      className="inline-flex lg:h-[30px] h-[25px] items-center cursor-pointer  justify-center gap-x-2 rounded-2xl bg-indigo-500 px-4 py-2.5 text-[16px] font-semibold text-gray-200 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {attioJsonLoading ? "loading" : "Add to CRM"}
                    </button>
                  )} */}

                  {/* <div className="flex flex-row cursor-pointer space-x-2 items-center">
                    <img
                      src="/images/flag.svg"
                      className="-mr-0.5 h-4 w-4"
                      aria-hidden="true"
                    />

                    <p className=" font-medium text-[14px] text-[#737373ff]">
                      Report Problem
                    </p>
                  </div> */}
                </div>

                <div className="flex flex-row space-x-4  items-center ">
                  {/* <div className="flex flex-row space-x-2 items-center">
                    <img src="/images/check-circle.svg" />
                    <p className=" font-medium text-[14px] text-[#737373ff]">
                      Saved
                    </p>
                  </div> */}

                  {/* <div className="h-[18px] w-[0.2px] bg-[#737373ff]" /> */}

                  {/* <div className="flex flex-row space-x-2 cursor-not-allowed items-center">
                    <div className=" w-[20px]">
                      <img src="/images/refresh.svg" />
                    </div>
                    <p className=" font-medium text-[14px] text-[#737373ff]">
                      See Past Revisions
                    </p>
                  </div> */}

                  <button
                    type="button"
                    onClick={() => {
                      setNewNotes(
                        recordingData?.title
                          ? recordingData?.title
                          : "Medical Record"
                      );
                      setOpenNewNoteModal(true);
                    }}
                    className="hidden lg:inline-flex lg:h-[30px] h-[25px] items-center cursor-pointer  justify-center gap-x-2 rounded-2xl bg-[#F34CA6] px-4 py-2.5 text-[16px] font-semibold text-gray-200 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Rename
                    <img
                      src="/images/rotate-right.svg"
                      className="-mr-0.5 h-4 w-4"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>

              <div className="mb-6 flex lg:flex-row flex-col lg:justify-between lg:items-center">
                <div className="flex flex-row  items-center space-x-4 min-w-[70%] ">
                  <audio
                    src={recordingUrl}
                    controls="controls"
                    className="mobile-full-width"
                    style={{ minWidth: "80%", maxHeight: 40 }}
                  />

                  <a href={recordingUrl} download style={{ color: "white" }}>
                    <ArrowDownOnSquareIcon
                      className="h-8 w-8 lg:h-8 lg:w-8"
                      aria-hidden="true"
                    />
                  </a>
                </div>

                <div
                  className="flex lg:flex-row lg:space-y-0 lg:space-x-6  space-y-3 flex-col lg:justify-between lg:ml-4 mt-4 lg:mt-0"
                  style={{ minWidth: 200 }}
                >
                  <div className="flex flex-row lg:space-x-4 space-x-4 lg:flex-1">
                    <div
                      className="flex px-4 flex-row lg:justify-center h-[40px] rounded-xl items-center lg:w-[64%] bg-[#2F3146]"
                      // style={{ width: "100%" }}
                      style={{ minWidth: 200 }}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <p className="text-[16px] text-gray-200 font-normal">
                          {recordingData?.createdAt
                            ? formatDate(recordingData?.createdAt)
                            : ""}
                          ,{" "}
                          {recordingData?.createdAt
                            ? formatTime(recordingData?.createdAt)
                            : ""}
                        </p>
                        <img
                          src="/images/calendar.svg"
                          // className="lg:block hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-8 flex flex-wrap flex-row cursor-pointer space-x-2 items-center md:hiddenhidden md:flex mb-8 flex-wrap flex-row cursor-pointer space-x-2 items-centerflex flex-wrap flex-row cursor-pointer space-x-2 items-center md:flex hidden">
                <button
                  type="button"
                  onClick={() =>
                    openTemplateModal(DEFAULT_MODAL_TYPE.RDO, "Redo Soap Note")
                  }
                  className="inline-flex mt-2 items-center cursor-pointer justify-center gap-x-2 rounded-2xl bg-indigo-600 px-4 py-1 text-[16px] font-semibold text-gray-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <FaMagic className="mr-2" /> Redo Note
                </button>
                <button
                  type="button"
                  onClick={() =>
                    openTemplateModal(DEFAULT_MODAL_TYPE.FN, "Fix Note with AI")
                  }
                  className="inline-flex  mt-2 items-center cursor-pointer justify-center gap-x-2 rounded-2xl bg-indigo-600 px-4 py-1 text-[16px] font-semibold text-gray-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <FaMagic className="mr-2" /> Fix Note with AI
                </button>
                <button
                  type="button"
                  onClick={() =>
                    openTemplateModal(
                      DEFAULT_MODAL_TYPE.RMN,
                      "Remake Note Using New Template"
                    )
                  }
                  className="inline-flex  mt-2 items-center cursor-pointer justify-center gap-x-2 rounded-2xl bg-indigo-600 px-4 py-1 text-[16px] font-semibold text-gray-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <FaMagic className="mr-2" /> Use New Template
                </button>
              </div>

              {isLoading ? (
                <>
                  {transcript?.transcript ? (
                    <div className=" flex flex-col space-y-2 ">
                      {/* STEVENDEBUG */}
                      <TextArea
                        title="Transcript"
                        bgColor={"gray"}
                        text={combineTranscripts(transcript?.transcript)}
                        // update={(newText) => updateText("plan", newText)}
                        style={{ maxHeight: "300px" }}
                      />
                    </div>
                  ) : (
                    <div className="border border-[#55566bff] shadow rounded-md p-4  w-full mx-auto mb-3">
                      <div className="animate-pulse flex space-x-4 min-h-[300px]">
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <p className="lg:text-[16px] text-left text-[16px] font-semibold text-white ">
                              Your transcript is being generated. Please come
                              back in a few seconds.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="border border-[#55566bff] shadow rounded-md p-4  w-full mx-auto">
                    <div className="animate-pulse flex space-x-4 min-h-[300px]">
                      <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                      <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-700 rounded"></div>
                        <div className="space-y-3">
                          <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                          </div>
                          <div className="h-2 bg-slate-700 rounded"></div>
                          <p className="lg:text-[16px] text-left text-[16px] font-semibold text-white ">
                            Your note is being generated. Please come back in a
                            minute.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" flex flex-col space-y-2 ">
                    {transcriptNote?.aiEnhancedTranscript && (
                      <TextArea
                        title="AI Enhanced Transcript"
                        bgColor={"gray"}
                        text={
                          transcriptNote?.aiEnhancedTranscript ||
                          "Transcript not available"
                        }
                        // update={(newText) => updateText("plan", newText)}
                        style={{ maxHeight: "150px", borderColor: "gray" }}
                      />
                    )}

                    <TextArea
                      title="Original Transcript"
                      bgColor={"gray"}
                      text={
                        combineTranscripts(transcript?.transcript) ||
                        "Transcript not available"
                      }
                      // update={(newText) => updateText("plan", newText)}
                      style={{ maxHeight: "150px", borderColor: "gray" }}
                    />

                    {customNotes?.length > 0 ? (
                      sortNotes(customNotes)?.map((customNote, customId) => {
                        return (
                          <TextArea
                            key={customId}
                            title={splitWords(customNote.name)}
                            bgColor={"#325ce6"}
                            style={{ borderColor: "gray" }}
                            text={transcriptNote[customNote.name]}
                            update={(newText) =>
                              updateText(customNote.name, newText)
                            }
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-row justify-center  mt-8">
                    <div className="flex flex-row space-x-4">
                      {/* <button
                    type="button"
                    className="rounded-full border border-indigo-600  bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Save Draft
                  </button> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default withAuthorization(EachNotePage);
