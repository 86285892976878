import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { StopIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  ListPartsCommand,
  CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase-config";
import { v4 as uuidv4 } from "uuid";
import { AttioDemoContext } from "../../context/attioDemo";
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import Uploader from "../Uploader";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { WavAudioContext } from "../../context/wavAudioContext";
import { ConfirmDeletion } from "../ConfirmDeletion";
import { MediaRecorder, register, deregister } from "extendable-media-recorder";
import { connect, disconnect } from "extendable-media-recorder-wav-encoder";
import { FreeTrialAlert } from "../FreeTrialAlert";
import { TrialPeriodContext } from "../../context/trialPeriod";
import { TrialPeriodExpired } from "../TrialRecordingExpired";

const s3 = new S3Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: "DO007HAKLKL49L67EFVP",
    secretAccessKey: "td9Qp547sxcrnMezrxUFH4hpiHtJekyWpT8DPEOJ9bA",
  },
  endpoint: "https://sfo2.digitaloceanspaces.com",
});

const nameSpace = [
  {
    id: 1,
    name: "US Namespace",
    url: "https://proxy-server-rbn8.onrender.com/medassist-us-transcribe",
  },
  {
    id: 2,
    name: "FRA Namespace",
    url: "https://proxy-server-rbn8.onrender.com/medassist-france-transcribe",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RecordingCard(props) {
  const { wavAudio, toggleWavMode } = useContext(WavAudioContext);
  const { userStatus, trialPeriodRecording, updateTrialPeriodRecording } =
    useContext(TrialPeriodContext);

  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);

  const { setAudioFiles } = props;
  const [isRecording, setIsRecording] = useState(false);
  const [noteTitle, setNoteTitle] = useState(
    attioDemo ? "Deal Memo" : "Medical Record"
  );
  const [blobURL, setBlobURL] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [openUploader, setOpenUploader] = useState(false);
  const [uploadedAudioFile, setUploadedAudioFile] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [duration, setDuration] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [multipartTestFile, setMultipartTestFile] = useState(null);
  const [showPresignedUrl, setShowPresignedUrl] = useState(false);

  // console.log("THE LIVE TRANSCRIPTION", attioDemo);

  const [isUploadingAllowed, setIsUploadingAllowed] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  //Trial Alert
  const [openTrialAlert, setOpenTrialAlert] = useState(false);

  //Trial Period Expired
  const [openTrialPeriodExpired, setOpenTrialPeriodExpired] = useState(false);

  const [isDebugModeLoading, setIsDebugModeLoading] = useState(true);
  const [debugMode, setDebugMode] = useState(false);

  const timerRef = useRef(null);
  const recordingFileNameRef = useRef(null);
  const uploadIdRef = useRef(null);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);

  const [selected, setSelected] = useState();
  const [selectedNamespace, setSelectedNamespace] = useState(nameSpace[0]);
  const [userTemplates, setUserTemplates] = useState();
  const auth = getAuth();

  const handleMultipartTestFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file size: ", file.size);
    setMultipartTestFile(file);
  };

  const handleMultipartTestUpload = useCallback(async () => {
    if (multipartTestFile) {
      console.log("ARE YOU BEING CALLED?? Handle MULTIPART TEST UPLOAD");
      setShowPresignedUrl(true); // Set the flag to true when starting test upload

      console.log(
        "Starting multipart upload for file size: ",
        multipartTestFile.size
      );

      // Start the multipart upload process
      const fileName = await createFileName();
      console.log("handleMultipartTestUpload> (1) fileName --->", fileName);
      const uploadId = await createUploadId(fileName);

      if (!uploadId) {
        console.error("Failed to create upload ID");
        return;
      }

      // Split the file into parts and upload each part
      const PART_SIZE = 5 * 1024 * 1024; // 5MB
      const fileSize = multipartTestFile.size;
      let partNumber = 1;

      for (let byteStart = 0; byteStart < fileSize; byteStart += PART_SIZE) {
        const byteEnd = Math.min(byteStart + PART_SIZE, fileSize);
        const part = multipartTestFile.slice(byteStart, byteEnd);

        // Log the size of the part
        console.log(
          `Uploading part ${partNumber}, size: ${part.size} bytes (${
            part.size / 1024 / 1024
          } MB)`
        );

        // Check if the part size is 0
        if (part.size === 0) {
          console.error(`Part ${partNumber} is empty. Aborting upload.`);
          return;
        }

        // Upload the part and add the returned ETag to the completedPartsRef
        await uploadPart(fileName, partNumber, uploadId, part);
        partNumber++;
      }

      // Complete the multipart upload
      await completeUpload(fileName, uploadId);
    } else {
      console.error("No file selected for upload.");
    }
  }, [multipartTestFile]);

  const getRecordingDocuments = async (id) => {
    const q = query(
      collection(db, "recordings"),
      where("userId", "==", id),
      orderBy("createdAt", "desc") // sort by createdAt field in descending order
    );
    const snapshot = await getDocs(q);
    console.log("this recordings documents", snapshot);

    const recordingsData = snapshot.docs
      .map((doc) => ({
        ...doc.data(),
        // id: doc.id,
      }))
      ?.filter((doc) => !doc.id.includes("-runloop")); // filter out documents with "-runloop" in their id
    setAudioFiles(recordingsData);
  };

  function formatDuration(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    const seconds = duration - hours * 3600 - minutes * 60;

    const hoursStr = String(hours).padStart(2, "0");
    const minutesStr = String(minutes).padStart(2, "0");
    const secondsStr = String(seconds).padStart(2, "0");

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  function setSelectedDefault(userTemplates) {
    // Find the template where isDefault is true
    const defaultTemplate = userTemplates.find(
      (template) => template.isDefault
    );

    // Set the selectedState as the default template
    setSelected(defaultTemplate);
  }

  async function setNewDefault(newDefault) {
    const userRef = doc(db, "user-templates", auth?.currentUser?.uid);

    // Get the current user templates from Firestore
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const data = userSnap.data();

      // Map over the templates
      const updatedTemplates = data.templates.map((template) => {
        // If the template is the new default, set isDefault to true
        // If the template is the old default, set isDefault to false
        return template.id === newDefault?.id
          ? { ...template, isDefault: true }
          : { ...template, isDefault: false };
      });

      // Update the userTemplates in Firestore
      await updateDoc(userRef, { templates: updatedTemplates });

      // Set the selectedState as the new default template
      const newDefaultTemplate = updatedTemplates.find(
        (template) => template.isDefault
      );
      setSelected(newDefaultTemplate);
    }
  }

  const getTemplates = async (userId) => {
    const userDocRef = doc(db, "user-templates", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      setUserTemplates(userDoc.data()?.templates);
      setSelectedDefault(userDoc.data()?.templates);
    } else {
      console.log("No such document!");
      return null;
    }
  };

  const completedPartsRef = useRef([]);
  const bufferRef = useRef([]);
  const bufferSizeRef = useRef(0);

  const portRef = useRef();

  const start = async () => {
    if (isBlocked) {
      console.log("Permission Denied");
      requestAudioPermission();
      return;
    }

    const fileName = await createFileName();
    console.log("start > fileName --->", fileName);
    let theIdForUpload = await createUploadId(fileName);

    if (!theIdForUpload) {
      return;
    }

    const result = MediaRecorder.isTypeSupported({ mimeType: "audio/wav" });

    console.log("THE RESULT", result);

    setIsRecording(true);
    mediaRecorder.start(1000);
    let partNumber = 1;

    mediaRecorder.addEventListener("dataavailable", async (e) => {
      console.log("YOU ARE BEING CALLLED");
      const mimeType = mediaRecorder.mimeType;

      bufferRef.current.push(e.data);
      bufferSizeRef.current += e.data.size;

      if (bufferSizeRef.current >= 5 * 1024 * 1024) {
        // Check if buffer size is at least 5MB
        const blob = new Blob(bufferRef.current, {
          type: mimeType,
        });

        uploadPart(fileName, partNumber, theIdForUpload, blob);

        partNumber++;
        console.log("clear buffer ehn");
        bufferRef.current = []; // Clear the buffer after uploading
        bufferSizeRef.current = 0;
      }
    });

    timerRef.current = setInterval(() => {
      setDuration((duration) => duration + 1);
    }, 1000);
  };

  async function createFileName() {
    let fileName;

    if (wavAudio) {
      fileName = `${auth?.currentUser?.uid}/recording_${Date.now()}.wav`;
    } else {
      fileName = `${auth?.currentUser?.uid}/recording_${Date.now()}.mp3`;
    }
    recordingFileNameRef.current = fileName; // Store fileName in the ref
    return fileName;
  }

  async function createUploadId(fileName) {
    const createParams = {
      Bucket: "vet-appt-recordings",
      Key: fileName,
    };

    let theIdForUpload;
    try {
      const createCommand = new CreateMultipartUploadCommand(createParams);
      const createResp = await s3.send(createCommand);
      console.log("Upload ID: ", createResp.UploadId);
      theIdForUpload = createResp.UploadId;
      uploadIdRef.current = theIdForUpload; // Store the uploadId in the ref
    } catch (err) {
      console.error("Error initiating multipart upload", err);
    }
    return theIdForUpload;
  }

  async function uploadPart(fileName, partNumber, theIdForUpload, part) {
    console.log("THE CREATED UPLOAD ID ---->", theIdForUpload);
    // Log the size of the part received by the function
    console.log(
      `Received part ${partNumber} for upload, size: ${part.size} bytes`
    );

    // Check if the part size is 0
    if (part.size === 0) {
      console.error(`Part ${partNumber} is empty. Aborting upload.`);
      return;
    }

    const params = {
      Bucket: "vet-appt-recordings",
      Key: fileName,
      PartNumber: partNumber,
      UploadId: theIdForUpload,
      Body: part,
    };

    try {
      console.log("THE PARAMS --->", params);
      const uploadPartCommand = new UploadPartCommand(params);

      console.log("WHAT IS THE UPLOAD PART COMMAND --->", uploadPartCommand);
      const uploadPartResp = await s3.send(uploadPartCommand);
      console.log("WHAT IS THE UPLOAD PART ERROR --->", uploadPartResp);

      console.log(`Uploaded part ${partNumber}, ETag: ${uploadPartResp.ETag}`);

      completedPartsRef.current.push({
        ETag: uploadPartResp.ETag,
        PartNumber: partNumber,
      });

      // Clear the buffer for the next part
      bufferRef.current = [];
      bufferSizeRef.current = 0;
    } catch (err) {
      console.error(`Error uploading part ${partNumber}: `, err);
    }
  }

  const pause = () => {
    if (mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
    }
  };

  const handleCancellation = () => {
    if (!openConfirmationModal) {
      if (!isPaused) {
        pause();
      }
      setOpenConfirmationModal(true);
    } else {
      try {
        mediaRecorder.stop();
        clearInterval(timerRef.current);
        setDuration(0);
        setIsRecording(false);
        setIsPaused(false);
        bufferRef.current = [];
        bufferSizeRef.current = 0;
        completedPartsRef.current = [];
        console.log("Clearing Buffer In Cancel Function");
        setOpenConfirmationModal(false);
      } catch (e) {
        console.log("ERROR", e);
      }
    }
  };

  const play = () => {
    if (mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);
      // Restart the timer
      timerRef.current = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
    }
  };

  const stop = () => {
    return new Promise(async (resolve, reject) => {
      console.log("STOP BUTTON IS BEING CALLED----> ");
      if (recordingFileNameRef.current && uploadIdRef.current) {
        setIsUploadingAllowed(false);
        mediaRecorder.stop();
        setIsRecording(false);
        setShowProgress(true);
        try {
          const blob = await createBlob();
          await uploadLastPart(
            blob,
            recordingFileNameRef.current,
            uploadIdRef.current
          ); // Use fileName from the ref
          await completeUpload(
            recordingFileNameRef.current,
            uploadIdRef.current
          ); // Use fileName from the ref
          updateTrialPeriodRecording(auth?.currentUser?.uid);

          resolve();
        } catch (error) {
          reject(error);
        } finally {
          mediaRecorder.removeEventListener("dataavailable", async (data) => {
            console.log("removed event handler", data);
          });
        }

        console.log("CALLED.... >");
        clearInterval(timerRef.current);
        setDuration(0);
        setIsRecording(false);
        setIsPaused(false);
      } else {
        console.error(
          "No file name or upload id available to complete upload."
        );
        reject("No file name or upload id available to complete upload.");
      }
    });
  };

  async function createBlob() {
    const blob = new Blob(bufferRef.current, {
      type: wavAudio ? "audio/wav" : "audio/webm",
    });
    return blob;
  }

  async function uploadLastPart(blob, fileName, uploadId) {
    console.log(
      "uploadLastPart > blob size in MB: ",
      blob.size / 1024 / 1024,
      " fileName: ",
      fileName,
      " uploadId: ",
      uploadId
    );

    if (bufferRef.current.length > 0) {
      const params = {
        Bucket: "vet-appt-recordings",
        Key: fileName,
        PartNumber: completedPartsRef.current.length + 1,
        UploadId: uploadId,
        Body: blob,
      };

      try {
        const uploadPartCommand = new UploadPartCommand(params);
        const uploadPartResp = await s3.send(uploadPartCommand);
        console.log("uploadLastPart > res details ", {
          ETag: uploadPartResp.ETag,
          PartNumber: completedPartsRef.current.length + 1,
          uploadPartResp,
        });
        completedPartsRef.current.push({
          ETag: uploadPartResp.ETag,
          PartNumber: completedPartsRef.current.length + 1,
        });

        console.log(
          "uploadLastPart > completedPartsRef.current ",
          completedPartsRef.current
        );
      } catch (err) {
        console.log("Error uploading part: ", err);
      }

      // Clear the buffer
      bufferRef.current = [];
      bufferSizeRef.current = 0;
    }

    setShowProgress(true);
  }

  async function completeUpload(fileName, uploadId) {
    // Ensure recordingFileName still holds the correct value here
    console.log("Recording file name at stop:", fileName); // Add this for debugging
    if (!fileName) {
      console.error("Recording file name is not set. Cannot complete upload.");
      return;
    }

    console.log(
      "completeUpload > completedPartsRef.current ",
      completedPartsRef.current
    );

    const listParams = {
      Bucket: "vet-appt-recordings",
      Key: fileName,
      UploadId: uploadId,
    };

    console.log("completeUpload > listParams ", listParams);

    try {
      const listPartsCommand = new ListPartsCommand(listParams);
      const listedParts = await s3.send(listPartsCommand);
      // Map over the listedParts.Parts array and create a new array that only includes the ETag and PartNumber properties
      const partsForUpload = listedParts.Parts.map((part) => ({
        ETag: part.ETag,
        PartNumber: part.PartNumber,
      }));
      console.log("Listed parts: ", partsForUpload);

      const completeParams = {
        Bucket: "vet-appt-recordings",
        Key: fileName,
        MultipartUpload: {
          Parts: partsForUpload.sort((a, b) => a.PartNumber - b.PartNumber),
        },
        UploadId: uploadId,
      };

      const completeCommand = new CompleteMultipartUploadCommand(
        completeParams
      );
      const completeResp = await s3.send(completeCommand);

      console.log(`File uploaded successfully at ${completeResp.Location}`);
      console.log(`File uploaded successfully at ${completeResp.Key}`);

      if (showPresignedUrl) {
        // Generate a pre-signed URL for the uploaded file
        const getObjectParams = {
          Bucket: "vet-appt-recordings",
          Key: fileName,
        };
        const getObjectCommand = new GetObjectCommand(getObjectParams);
        const signedUrl = await getSignedUrl(s3, getObjectCommand, {
          expiresIn: 3600, // URL expires in 1 hour
        });

        alert(`${signedUrl}`);
      }

      if (completeResp?.Key) {
        console.log("THE RESPONSE KEY", completeResp?.Key);
        await handleResponseKey(completeResp?.Key);
        setIsUploadingAllowed(true);
      }
    } catch (err) {
      console.error("Error completing multipart upload", err);
    }
  }

  async function handleResponseKey(key) {
    try {
      const recordingUUID = uuidv4();
      const docRef = await addDoc(collection(db, "recordings"), {
        title: noteTitle.length > 1 ? noteTitle : "Unnamed Note",
        id: recordingUUID,
        record_key: key,
        userId: auth?.currentUser?.uid,
        createdAt: new Date(),
      });

      const runloopDocRef = await addDoc(collection(db, "recordings"), {
        title: noteTitle.length > 1 ? noteTitle : "Unnamed Note",
        id: `${recordingUUID}-runloop`,
        record_key: key,
        userId: auth?.currentUser?.uid,
        createdAt: new Date(),
      });

      setNoteTitle(attioDemo ? "Deal Memo" : "Medical Record");
      await getRecordingDocuments(auth?.currentUser?.uid);
      // Update the inbox to show the new recording

      const docSnap = await getDoc(doc(db, "recordings", docRef.id));

      const runloopDocSnap = await getDoc(
        doc(db, "recordings", runloopDocRef.id)
      );

      // Make a POST request
      const transcriptionBody = JSON.stringify({
        fileName: docSnap.data().record_key,
        recordId: docSnap.data().id,
        userId: auth?.currentUser?.uid,
        templateId: selected ? selected?.id : null,
      });
      console.log("transcriptionBody: ", transcriptionBody);

      fetch(`${selectedNamespace.url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: transcriptionBody,
      });

      fetch("https://proxy-server-rbn8.onrender.com/generate_soap", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer sk_prod_qrdbYPEsgrzeeFVebd1tt+/qe8li8Xq+RYJkViHztCE=",
        },
        body: JSON.stringify({
          request: {
            recording_id: runloopDocSnap.data().id,
            user_id: auth?.currentUser?.uid,
            template_id: selected ? selected?.id : "",
            uploaded_transcript: "",
            type: "newRecording",
            file_name: runloopDocSnap.data().record_key,
          },
        }),
      });

      setShowProgress(false);
      // Set complete state to true after API request
      setIsComplete(true);
      // Set complete state back to false after 2 seconds
      setTimeout(() => {
        setIsComplete(false);
      }, 1000);
      // Clear the buffer
      bufferRef.current = [];
      bufferSizeRef.current = 0;
      completedPartsRef.current = [];
      console.log("CLEARING BUFFER");

      console.log("Transcription lambda response: ");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const requestAudioPermission = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        },
      })
      .then((mediaStream) => {
        // Handle successful permission grant and stream acquisition
        streamRef.current = mediaStream;
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(
          streamRef.current
        );
        const gainNode = audioContextRef.current.createGain();
        const destination =
          audioContextRef.current.createMediaStreamDestination();

        source.connect(gainNode);
        gainNode.connect(destination);

        gainNode.gain.value = 1.0; // Adjust gain as needed

        const newMediaRecorder = new MediaRecorder(destination.stream);
        setMediaRecorder(newMediaRecorder);
        console.log("Permission Granted");
        setIsBlocked(false);
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          // Inform the user they need to allow microphone access
          alert("Please allow microphone access in your browser settings.");
          console.log("Permission Denied", err);
          setIsBlocked(true);
        } else {
          console.log("Permission Denied", err);
          setIsBlocked(true);
        }
      });
  };

  async function setupAudioRecording() {
    console.log("SETTING UP THE RECORDER", portRef.current);

    let newEncoder;
    try {
      if (portRef.current) {
        try {
          await deregister(portRef);
        } catch (e) {
          console.log("DEREGISTERRING Error >");
        }
      }

      newEncoder = await connect();

      try {
        await register(newEncoder);
        portRef.current = newEncoder;
      } catch (e2) {
        console.log("E2: " + e2);
      }
    } catch (e1) {
      console.log("E1: " + e1);
    }
    await navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        },
      })
      .then((mediaStream) => {
        streamRef.current = mediaStream;
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(
          streamRef.current
        );
        const gainNode = audioContextRef.current.createGain();
        const destination =
          audioContextRef.current.createMediaStreamDestination();

        source.connect(gainNode);
        gainNode.connect(destination);

        gainNode.gain.value = 1.0; // Adjust gain as needed
        const newMediaRecorder = new MediaRecorder(destination.stream, {
          mimeType: wavAudio ? "audio/wav" : "audio/webm",
          audioBitsPerSecond: 44100,
        });
        setMediaRecorder(newMediaRecorder);
        console.log("Permission Granted");
        setIsBlocked(false);
      })
      .catch((err) => {
        console.log("Permission Denied", err);
        setIsBlocked(true);
      });
  }

  useEffect(() => {
    setupAudioRecording();
    // Cleanup function
    return () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const fetchDebugMode = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      setDebugMode(userData.debugMode || false);
      setIsDebugModeLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      getTemplates(auth?.currentUser?.uid);
      fetchDebugMode(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    handleMultipartTestUpload();
  }, [handleMultipartTestUpload]);

  return (
    <>
      <Uploader
        openUploader={openUploader}
        setOpenUploader={setOpenUploader}
        currentTemplate={selected}
        getRecordingDocuments={getRecordingDocuments}
      />

      <FreeTrialAlert
        startRecording={start}
        openTrialAlert={openTrialAlert}
        setOpenTrialAlert={setOpenTrialAlert}
      />

      <TrialPeriodExpired
        openTrialPeriodExpired={openTrialPeriodExpired}
        setOpenTrialPeriodExpired={setOpenTrialPeriodExpired}
      />

      <ConfirmDeletion
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        handleCancellation={handleCancellation}
      />
      <div className="p-1 flex flex-col rounded-md w-full  border-[0.5px] border-[#2D2D39] min-h-[600px] shadow-lg">
        <div className=" border-b-1  bg-[#242536] flex flex-row  justify-between items-center px-4 py-3 w-full rounded-md shadow-sm ">
          {attioDemo ? (
            <p
              className=" text-left text-[white] lg:text-[28px]  text-[18px] font-bold "
              onClick={stop}
            >
              Meetings
            </p>
          ) : (
            <p
              className=" text-left text-[white] lg:text-[28px]  text-[18px] font-bold "
              onClick={stop}
            >
              Create a Recording
            </p>
          )}

          <div class="flex flex-col items-end">
            {/* <button
              type="button"
              onClick={() => setOpenUploader(true)}
              className="inline-flex lg:flex hidden items-center gap-x-1.5 rounded-md bg-[blue] px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Upload Audio
              <img
                src="/images/receive-square.svg"
                className="-mr-0.5 h-5 w-5 text-white"
                aria-hidden="true"
              />
            </button> */}

            {debugMode && !isDebugModeLoading && (
              <>
                <button
                  type="button"
                  onClick={() => document.getElementById("fileInput").click()}
                  className="mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-[blue] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Test Multipart Upload
                  <img
                    src="/images/receive-square.svg"
                    className="-mr-0.5 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleMultipartTestFileChange}
                />

                <div className="flex items-center mt-4">
                  <input
                    id="show-presigned-url"
                    name="show-presigned-url"
                    type="checkbox"
                    checked={showPresignedUrl}
                    onChange={(e) => setShowPresignedUrl(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="show-presigned-url"
                    className="ml-2 block text-sm text-white"
                  >
                    Access File URL on Upload Completion
                  </label>
                </div>

                {/* TODO: display which namespace/url transcribe is called with after stop() is pressed */}
                <div>
                  <p className="text-white text-sm mt-4">
                    {selectedNamespace.url}
                  </p>
                </div>
              </>
            )}
          </div>

          {/* Don't show upload audio button on mobile view */}
          {/* <button
            type="button"
            onClick={() => setOpenUploader(true)}
            className="inline-flex lg:hidden  items-center gap-x-1.5 rounded-md bg-[#282847ff] px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Upload
            <img
              src="/images/receive-square.svg"
              className="-mr-0.5 h-5 w-5"
              aria-hidden="true"
            />
          </button> */}
        </div>

        <div className="px-1 space-x-2 mt-4 flex items-center flex-col sm:flex-row">
          <div className="relative rounded-md shadow-sm w-full sm:w-3/5">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <img
                src="/images/note.svg"
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="email"
              name="note-title"
              type="text"
              value={noteTitle}
              onChange={(e) => setNoteTitle(e.target.value)}
              placeholder="Note title (optional)"
              required
              className="block pl-8 pr-3 w-full  bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>

          <Listbox value={selected} onChange={setNewDefault}>
            {({ open }) => (
              <>
                {/* TODO: figure out why this didn't work with tailwind classnames */}
                <style>
                  {`
                    @media (max-width: 639px) {
                      .small-screen-only {
                        margin-left: 0 !important;
                      }
                    }
                  `}
                </style>
                {/* <div className="lg:hidden flex width-full mt-2">
                <p className="text-left text-white">Template:</p>
                </div> */}
                <div className="relative w-full sm:w-2/5 mt-2 sm:mt-0 flex items-center small-screen-only">
                  <Listbox.Button
                    placeholder="Templates"
                    className="relative w-full cursor-default rounded-md bg-[#2F3146] py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:leading-6"
                    style={{ minHeight: 38 }}
                  >
                    <span className="flex items-center space-x-2">
                      <span className=" block truncate font-normal lg:text-[14px] md:text-[14px] text-gray-200">
                        {attioDemo ? (
                          "Attio Template"
                        ) : (
                          <span>
                            {" "}
                            {selected?.name
                              ? "Template: " + selected?.name
                              : "Template: MedAssist SOAP"}
                          </span>
                        )}
                      </span>
                      <img
                        src="/images/information-circle.svg"
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-white "
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {userTemplates?.map((template) => (
                        <Listbox.Option
                          key={template.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-indigo-600 text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={template}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {template.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        {/* Hiding for now */}
        {/* <div className="px-1 space-x-2 mt-2 flex items-center flex-row">
          <Listbox value={selectedNamespace} onChange={setSelectedNamespace}>
            {({ open }) => (
              <>
                <div className="relative w-[40%]">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-[#2F3146] py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                    <span className="flex items-center space-x-2">
                      <span className=" block truncate font-normal text-[14px] text-gray-200">
                        {selectedNamespace.name}
                      </span>
                      <img
                        src="/images/information-circle.svg"
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-white "
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {nameSpace.map((nameSpace) => (
                        <Listbox.Option
                          key={nameSpace.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-indigo-600 text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={nameSpace}
                        >
                          {({ selectedNamespace, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selectedNamespace
                                      ? "font-semibold"
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {nameSpace.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div> */}

        <div className="px-4 mt-6 mb-6 flex flex-col flex-1 justify-center items-center align-middle cursor-pointer">
          {isComplete && !showProgress && (
            <div className=" flex flex-row justify-center items-center ">
              <img src="/images/record-success.svg" />
            </div>
          )}
          {showProgress && (
            <div className=" flex flex-row justify-center items-center ">
              <div className=" flex flex-row justify-center items-center w-[200px] h-[200px] rounded-full ">
                <svg
                  className="animate-spin -ml-1 mr-3 h-20 w-20 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#304fd9ff"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="white"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {/* <p className="text-white text-[50px] font-bold">{`${progress}%`}</p> */}
              </div>
            </div>
          )}
          {!isRecording && !isPaused && !isComplete && !showProgress && (
            <>
              <div
                className="relative flex flex-row justify-center"
                style={{ maxWidth: "99%" }}
              >
                {/* animate-ping */}
                {/* <div className=" absolute animate-ping inline-flex h-full w-full rounded-full top-0 left-0 right-0 bottom-0 bg-[#6D95FF]  opacity-75"></div> */}
                <div className="bg-[#1f2c4f] px-2 py-2 relative w-[380px] flex flex-row justify-center items-center  rounded-full">
                  <div
                    onClick={() => {
                      if (userStatus.isOnTrialPeriod) {
                        setOpenTrialAlert(true);
                      } else if (
                        !userStatus.isBillingDisabled &&
                        !userStatus.hasActiveSubscription
                      ) {
                        setOpenTrialPeriodExpired(true);
                      } else {
                        start();
                      }
                    }}
                    className="flex flex-row justify-center items-center relative py-6 w-full rounded-full  bg-gradient-to-tr from-[#2C3CC8] via-[#6D95FF] to-[#3970E9] "
                  >
                    <div className="flex flex-row items-center space-x-4">
                      <img
                        src="/images/mic.svg"
                        className="  cursor-pointer "
                      />
                      <p className="text-white text-[18px] font-semibold">
                        CAPTURE CONVERSATION
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <p>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6 mt-4">
                    <span className="bg-[#1A1A26]  px-4 text-white">OR</span>
                  </div>
                </div>

                <p className="mt-1 flex flex-row">
                  <p className="text-white text-sm">Upload a </p>
                  <p
                    className="text-[#5878EE] text-sm text-center ml-1"
                    onClick={() => setOpenUploader(true)}
                  >
                    {attioDemo
                      ? " meeting conversation"
                      : " pre-recorded visit"}
                  </p>
                </p>
              </p>
            </>
          )}

          {isRecording && (
            <div className="w-full flex flex-col items-center">
              {isPaused && (
                <div className="relative  flex flex-row justify-center">
                  {/* animate-ping */}
                  {/* <div className=" absolute animate-ping inline-flex h-full w-full rounded-full top-0 left-0 right-0 bottom-0 bg-[#6D95FF]  opacity-75"></div> */}
                  <div
                    onClick={stop}
                    className="bg-[#b0b0b0] px-2 py-2 relative w-[280px] flex flex-row justify-center items-center  rounded-full"
                  >
                    <div className="flex flex-row justify-center items-center relative py-4 w-full rounded-full  bg-gradient-to-tr from-[#999999] via-[#D8D8D8] to-[#A1A1A1] ">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src="/images/stop.svg"
                          className=" cursor-pointer "
                        />
                        <p className="text-white text-[18px] font-semibold">
                          END VISIT
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                // <div className="h-[250px] w-[250px] flex flex-row justify-center items-center rounded-full">
                //   <img
                //     src="images/big-pause-stop-btn.svg"
                //     width={"60%"}
                //     height="60%"
                //     color="white"
                //     cursor="pointer"
                //   />
                // </div>
              )}

              {!isPaused && (
                <div className="relative  flex flex-row justify-center">
                  {/* animate-ping */}
                  <div className=" absolute animate-ping inline-flex h-full w-full rounded-full top-0 left-0 right-0 bottom-0 bg-[#6D95FF] opacity-75"></div>
                  <div
                    onClick={stop}
                    className="bg-[#1f2c4f] px-2 py-2 relative w-[280px] flex flex-row justify-center items-center  rounded-full"
                  >
                    <div className="flex flex-row justify-center items-center relative py-6 w-full rounded-full  bg-gradient-to-tr from-[#2C3CC8] via-[#6D95FF] to-[#3970E9] ">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src="/images/mic.svg"
                          className=" cursor-pointer "
                        />
                        <p className="text-white text-[18px] font-semibold">
                          END VISIT
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Handles pause states button display */}
              <div className="flex mt-10 flex-row justify-center w-full">
                <div className="flex flex-row items-center space-x-4">
                  {!isPaused && (
                    <img src="/images/Pause.svg" alt="" onClick={pause} />
                  )}

                  {isPaused && (
                    <img src="/images/play-button.svg" alt="" onClick={play} />
                  )}

                  <p className="text-white text-[18px]">
                    {" "}
                    {formatDuration(duration)}
                  </p>

                  <img
                    src="/images/stop-button.svg"
                    alt=""
                    onClick={handleCancellation}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
