import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
export default function SuccessPage() {
  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");

  const handlePaymentSuccess = async (sessionId) => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://vetassist-mobile-backend.onrender.com/payment-success",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: sessionId,
            userId: auth.currentUser?.uid,
          }),
        }
      );

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Handle the response data
      navigate("/dashboard");
      console.log(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("An error occurred:", error);
    }
  };

  return (
    <div className=" flex flex-col h-[100vh] py-10">
      <main className=" flex flex-col  justify-center flex-[1]">
        <div className="mx-auto  max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h4 className="mt-2 text-lg font-bold tracking-tight text-indigo-600 sm:text-md">
              Subscription Created
            </h4>
          </div>

          <div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Your subscription was created. You can now proceed to your
              dashboard.
            </p>
          </div>

          <div className="flex flex-row justify-center">
            <button
              onClick={() => {
                if (auth?.currentUser?.uid) {
                  handlePaymentSuccess(sessionId);
                }
              }}
              className=" mt-5 flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? (
                <div className="w-4 h-4 border-2 border-t-2 border-white rounded-full animate-spin"></div>
              ) : (
                "Proceed to Dashboard"
              )}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
