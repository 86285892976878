import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import { calculateRows } from "../../utils/helper-functions";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import { AttioDemoContext } from "../../context/attioDemo";

export const TextArea = (props) => {
  const { title, text, update, bgColor, hideIcon, style } = props;
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);

  return (
    <>
      <ToastContainer autoClose={1000} />

      <div className="relative" style={{ marginBottom: 10 }}>
        <textarea
          style={{ backgroundColor: "#2F3146", ...style }}
          className={`w-full  py-6 px-2  border-2 rounded-md focus:outline-none text-[#fafafaff] focus:ring-0`}
          rows={title === "Objective" ? 10 : calculateRows(text)}
          value={text}
          onChange={(e) => {
            if (props?.update) {
              update(e.target.value);
            }
          }}
        ></textarea>

        <div
          style={{ backgroundColor: bgColor }}
          className={` absolute left-0 -top-4 px-3 py-1 rounded-tl-3xl rounded-r-3xl `}
        >
          <p className="text-[13px] font-bold capitalize text-white">{title}</p>
        </div>

        {attioDemo ? (
          <></>
        ) : (
          <>
            {!hideIcon && (
              <div
                onClick={() => {
                  copy(text);
                  toast("Copied to clipboard", { type: "success" });
                }}
                className=" cursor-pointer  absolute -right-1 -top-4 flex flex-row justify-center items-center  bg-[#2664ebff] h-[40px] w-[40px] rounded-full"
              >
                <DocumentDuplicateIcon height={30} width={30} color="white" />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
