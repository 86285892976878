import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ClockIcon, XMarkIcon, UserIcon } from "@heroicons/react/24/outline";

import { withAuthorization } from "../../hoc/auth.hoc";
import { useNavigate, useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import {
  formatTime,
  formatDate,
  andriodPlatform,
} from "../../utils/helper-functions";
import { DashboardItems } from "../../utils/constant";
import NewNoteModal from "../../component/Rename";
import { getAuth } from "firebase/auth";
import Banner from "../../component/Banner";
import NoteInfo from "../../component/NotesInfo";
import { AttioDemoContext } from "../../context/attioDemo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NotesPage = () => {
  const { toggleDebugMode, attioDemo } = useContext(AttioDemoContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [openNoteInfo, setOpenNoteInfo] = useState(false);
  const [newNotes, setNewNotes] = useState("Medical Record");
  const [recordingId, setRecordingId] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openNewNoteModal, setOpenNewNoteModal] = useState(false);

  const auth = getAuth();

  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const handleUserSubscriptionCheck = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists() && !userDocSnap.data().isBillingDisabled) {
      setSubscriptionActive(true);
    }
  };

  const [audioFiles, setAudioFiles] = useState([]);
  const getDocument = async (id) => {
    const q = query(
      collection(db, "recordings"),
      where("userId", "==", id),
      orderBy("createdAt", "desc") // sort by createdAt field in descending order
    );
    const snapshot = await getDocs(q);
    console.log("Filtered recordings documents", snapshot);
    const recordingsData = snapshot.docs
      .map((doc) => ({
        ...doc.data(),
      }))
      .filter((doc) => !doc.id.includes("-runloop"));
    setAudioFiles(recordingsData);
  };

  useEffect(() => {
    if (auth.currentUser.uid) {
      getDocument(auth.currentUser.uid);
      handleUserSubscriptionCheck(auth.currentUser.uid);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const response = navigator?.userAgentData?.platform || navigator?.platform;

    console.log("IOS STATUS", response);
  }, []);

  return (
    <div>
      <NewNoteModal
        newNotes={newNotes}
        setNewNotes={setNewNotes}
        recordingId={recordingId}
        openNewNoteModal={openNewNoteModal}
        setOpenNewNoteModal={setOpenNewNoteModal}
        isDashboard={true}
        getDoc={getDocument}
      />
      {/* <NoteInfo setOpenNoteInfo={setOpenNoteInfo} openNoteInfo={openNoteInfo} /> */}
      <div
        className="bg-[#1A1A26] overflow-y-scroll  no-scrollbar"
        style={{ minHeight: 1500 }}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      {attioDemo ? (
                        <img
                          className="h-8 w-auto"
                          src={"/images/the_general_partnership_cover.jpeg"}
                          // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                          alt="Your Company"
                        />
                      ) : (
                        <img
                          className="h-12 w-auto"
                          src={"/images/MedAssist-white.svg"}
                          // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                          alt="Your Company"
                        />
                      )}
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {DashboardItems(subscriptionActive, attioDemo)?.map(
                              (item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    if (item.key === "/notes") {
                                      setOpenNoteInfo(true);
                                    } else {
                                      navigate(item.key);
                                    }
                                  }}
                                >
                                  <a
                                    className={classNames(
                                      item.key === location.pathname
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#242536] shadow-lg px-6 pb-4">
            {attioDemo ? (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-8 w-auto "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            ) : (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto"
                  src="/images/MedAssist-white.svg"
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            )}

            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {DashboardItems(subscriptionActive, attioDemo)?.map(
                      (item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            if (item.key === "/notes") {
                              setOpenNoteInfo(true);
                            } else if (
                              item.name === "Support" ||
                              item.name === "Templates"
                            ) {
                              navigate("/settings");
                            } else {
                              navigate(item.key);
                            }
                          }}
                        >
                          <a
                            className={classNames(
                              item.key === location.pathname
                                ? "bg-indigo-600 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-[6px] p-2 text-[14px] leading-6 font-medium"
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60 bg-[#1A1A26]">
          <div className="sticky bg-[#1A1A26] top-0 z-40 lg:hidden flex justify-between h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/images/hamburger-menu.svg"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            {attioDemo ? (
              <div className="flex h-16 shrink-0 items-center py-12">
                {/* <img
                  className="h-12 w-auto "
                  src={"/images/the_general_partnership_cover.jpeg"}
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                /> */}
              </div>
            ) : (
              <div className="flex h-16 shrink-0 items-center py-12">
                <img
                  className="h-12 w-auto"
                  src="/images/MedAssist-white.svg"
                  // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                />
              </div>
            )}

            <div className="inline-flex items-center lg:hidden gap-x-1.5">
              <UserIcon
                onClick={() => navigate("/settings")}
                color="white"
                className="h-[32px w-[32px] flex-shrink-0 rounded-full lg:hidden "
              />
            </div>
          </div>

          <main className="lg:h-[100vh]  bg-[#1A1A26] lg:overflow-y-scroll lg:no-scrollbar no-scrollbar">
            <div className="hidden lg:block  lg:no-scrollbar lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 pt-8  ">
              <h1 className="text-left text-[30px] font-bold text-white">
                Notes
              </h1>
            </div>

            <div className="flex lg:space-x-10 lg:space-y-0 space-y-10 lg:flex-row flex-col px-4 sm:px-6 lg:px-8 overflow-y-scroll no-scrollbar">
              <div className="flex-1 ">
                <div className=" flex flex-col rounded-md w-full  min-h-[500px]  shadow-lg p-1">
                  {audioFiles.length == 0 && (
                    <div className=" flex flex-1 flex-col justify-center">
                      <p className="text-white">
                        You have no new notes yet! Go to "Record Now" to record
                        a new appointment
                      </p>
                    </div>
                  )}
                  <div className=" py-4 flex flex-col space-y-2 mt-6     overflow-y-scroll no-scrollbar ">
                    {audioFiles?.map((audio, audioKey) => {
                      // console.log("THE AUDIO", audio);
                      return (
                        <div
                          key={audioKey}
                          className="flex flex-row justify-between cursor-pointer items-center rounded-md shadow-sm px-5 min-h-[70px] max-h-[60px] w-full bg-[#2F3146]"
                        >
                          <div
                            className="w-full"
                            onClick={() => navigate(`/notes/${audio.id}`)}
                          >
                            <p className="text-gray-200 text-left font-semibold text-[16px] truncate">
                              {audio.title || "Medical Record "}
                            </p>
                            <div className=" mt-1 flex items-center flex-row space-x-2">
                              <ClockIcon className="-mr-0.5 h-4 w-4 text-gray-400" />{" "}
                              <p className="text-gray-400 font-normal text-[12px] ">
                                {formatDate(audio.createdAt)},{" "}
                                {formatTime(audio.createdAt)}
                              </p>
                            </div>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              setRecordingId(audio.id);
                              setNewNotes(
                                audio.title ? audio.title : "Unnamed Note"
                              );
                              setOpenNewNoteModal(true);
                            }}
                            className="hidden md:inline-flex h-[30px] items-center justify-center gap-x-2 rounded-md bg-[#F34CA6] px-4 py-2.5 text-[16px] font-semibold text-gray-200 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Rename
                            <img
                              src="/images/rotate-right.svg"
                              className="-mr-0.5 h-4 w-4"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default withAuthorization(NotesPage);
