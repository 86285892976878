import { AttioDemoContext } from "../../context/attioDemo";
import { useContext } from "react";
const Banner = (props) => {
  const { attioDemo } = useContext(AttioDemoContext);
  const { bgColor, color } = props;
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className="flex px-6 rounded-sm flex-row  w-screen justify-center items-center h-[50px]  sticky top-0 z-40 sm:px-6 lg:px-8"
    >
      {attioDemo ? (
        <p style={{ color: color }} className="text-center font-semibold">
          GP {"<>"} Attio - Demo 5/13/2024
        </p>
      ) : (
        <p style={{ color: color }} className="text-center font-semibold">
          {/* MedAssist now has a mobile app! Download here for{" "}
          <span className=" underline cursor-pointer ">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.mycompany.vetassist"
            >
              Android
            </a>
          </span>{" "}
          or{" "}
          <span className=" underline cursor-pointer ">
            <a
              target="_blank"
              href="https://testflight.apple.com/join/VYwydJh8"
            >
              iOS
            </a>
          </span>
          {"."} */}
          Questions on using MedAssist? Email chester@recon.health or call/text{" "}
          <span className=" underline cursor-pointer ">+1 (707) 563-9783</span>
        </p>
      )}
    </div>
  );
};

export default Banner;
