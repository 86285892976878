import { XMarkIcon, PencilIcon } from "@heroicons/react/24/outline";

export const SectionBox = (props) => {
  const { name, description, currentPage, handleEditInstance, deleteSection } =
    props;
  return (
    <div className=" px-3 flex flex-row space-x-2 items-center justify-center h-[40px] relative rounded-md shadow-md bg-gray-600">
      {currentPage === 2 && (
        <PencilIcon
          onClick={() => handleEditInstance(name, description)}
          color="white"
          className="   top-[0] z-4 right-0 h-4 w-4 bg-gray-600  cursor-pointer"
        />
      )}

      <p className="text-sm font-light text-white ">{name}</p>
      <XMarkIcon
        onClick={() => deleteSection(name)}
        color="red"
        className="  top-[0] z-4 right-0 h-4 w-4 bg-gray-600  cursor-pointer"
      />
    </div>
  );
};
