import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { getAuth } from "firebase/auth";
import { db } from "../../firebase-config";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";

export default function NewNoteModal(props) {
  const {
    openNewNoteModal,
    recordingId,
    setOpenNewNoteModal,
    newNotes,
    setNewNotes,
    getDoc,
    isDashboard,
    getRecording,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();

  const handleNoteChange = async () => {
    setIsLoading(true);

    const q = query(
      collection(db, "recordings"),
      where("id", "==", recordingId)
    );

    const querySnapshot = await getDocs(q);

    console.log("THE NOTE CHANGE DOC", querySnapshot);

    querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      updateDoc(docRef, { title: newNotes })
        .then(() => {
          setIsLoading(false);
          if (isDashboard) {
            getDoc(auth.currentUser.uid);
          } else {
            getRecording();
          }

          setOpenNewNoteModal(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(`Error updating document with id ${doc.id}: `, error);
        });
    });
  };

  return (
    <>
      <ToastContainer />
      <Transition.Root show={openNewNoteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenNewNoteModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#191A26] px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50%] sm:p-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-0 w-full  text-center  sm:mt-0 sm:text-left">
                      <div className=" flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:justify-between sm:items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-[18px] text-left  font-semibold leading-6 text-gray-50"
                        >
                          Rename Recording
                        </Dialog.Title>
                      </div>

                      <div className="mt-6">
                        <p className="text-[12px] text-left font-normal text-gray-400"></p>

                        <div className=" mt-3 flex flex-row items-center space-x-6">
                          <input
                            id="email"
                            name="note-title"
                            type="text"
                            value={newNotes}
                            onChange={(e) => setNewNotes(e.target.value)}
                            placeholder="Note Title"
                            required
                            className="block pl-2 pr-3 w-full  bg-[#2F3146]  rounded-md border-0 border-[#2D2D39] py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {isLoading ? (
                      <button
                        type="button"
                        disabled={true}
                        className=" inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white   shadow-sm sm:ml-3 sm:w-auto"
                        onClick={() => handleNoteChange()}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
