import React, { createContext, useContext, useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { getAuth, signOut } from "firebase/auth";

export const AttioDemoContext = createContext();

export const AttioDemoProvider = ({ children }) => {
  const auth = getAuth();
  const [attioDemo, setAttioDemo] = useState(() => {
    const storedAttio = localStorage.getItem("attioDemo");
    if (storedAttio && storedAttio !== "undefined") {
      try {
        return JSON.parse(storedAttio);
      } catch (e) {
        console.error("Error parsing attioDemo from localStorage:", e);
        // Handle the error, e.g., by returning a default value or null
        return null;
      }
    }
    return null;
  });

  const toggleDebugMode = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const newAttioDemoValue = !userData.attioDemo;

      await updateDoc(userDocRef, { attioDemo: newAttioDemoValue });
      setAttioDemo(newAttioDemoValue);
      localStorage.setItem("attioDemo", JSON.stringify(newAttioDemoValue));
    }
  };

  useEffect(() => {
    const fetchAttioDemo = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const attioDemoValue = userData?.attioDemo;

        setAttioDemo(attioDemoValue);
        localStorage.setItem("attioDemo", JSON.stringify(attioDemoValue));
      }
    };

    if (auth?.currentUser?.uid) {
      fetchAttioDemo(auth?.currentUser?.uid);
    }
  }, [auth?.currentUser?.uid]);

  return (
    <AttioDemoContext.Provider value={{ attioDemo, toggleDebugMode }}>
      {children}
    </AttioDemoContext.Provider>
  );
};
